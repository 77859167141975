import React, { Component, createContext } from "react";
//import { Router, Route, Redirect } from "react-router-dom";

import firebase from "firebaseConfig";
import * as FIRESTORE_CONSTANTS from "constants/cloudfirestore";
import moment from 'moment';
/*
const initialState = { user: null, email: "", password: "" };
export const UserContext = createContext(initialState);
const { Provider } = UserContext;

export const UserProvider = ( { children } ) => {
const newState = {};
  const [state, dispatch] = useReducer((state, action) => {
    switch(action.type) {
      case 'AuthStateChange':
          newState = {};
          debugger;
          firebase.auth().onAuthStateChanged(userAuth => {
            newState = { user: userAuth};
            debugger;
          });
          return newState;
      case 'Login':
              newState = {};
              debugger;
              firebase.auth().signInWithEmailAndPassword(state.email, state.password).then(
                (userAuth) => {
                  console.log("login is true, lets redirect, why didnt it break?");
                  console.log("User ID: ", userAuth.user.uid);
                  newState = { user: userAuth};
                  debugger;
                })
                .catch(function(error){
                  alert(error)
                })
            //  firebase.auth().onAuthStateChanged(userAuth => {
            //    newState = { user: userAuth};
            //  });
              return newState;
      case 'SignOut':
          newState = {};
          debugger;
          firebase.auth().signOut().then(
             () => {
              console.log("signout is true, let's redirect");
              newState = { user: null};
              debugger;

            })
            .catch(function(error){
              alert(error)
            })
            return  newState;
      default:
         throw new Error();
    };
  }, initialState);


  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export default { UserProvider }
*/


export const UserContext = createContext({ loading: true, user: null });

class UserProvider extends Component {

  state = {};

  constructor(props) {
    super(props);
    this.state = {
        loading: true,
        user: null
      };
  }

  componentDidMount(){
    firebase.auth().onAuthStateChanged( userAuth => {
       this.setState({ loading: false, user: userAuth});
       this.state.user ? console.log("AuthState: User ID: ", this.state.user.uid): console.log("AuthState: User is logged out");
    })
  };

  loginAction = (email, password) => {
     this.setState({ loading: true});
     debugger
     return (
        firebase.auth().signInWithEmailAndPassword(email, password).then(
        (userAuth) => {
          console.log("login is true, lets redirect, why didnt it break?");
          console.log("User ID: ", userAuth.user.uid);
          this.setState({ loading: false, user: userAuth});
        })
    )

  }

  signoutAction =() => {
    return firebase.auth().signOut();
  };

resetPasswordAction = (email) => {
     this.setState({ loading: true});
     debugger;
     return (
        firebase.auth().sendPasswordResetEmail(email).then( (resp) => {
          // Email sent.
          console.log("Reset Password: Email Sent");
          debugger;
          this.setState({ loading: false});
        })
    )
  }

  async signUpAction(email, password, firstName, lastName){
    this.setState({ loading: true});
    debugger
    var databaseRef = firebase.firestore();
    return (
       firebase.auth().createUserWithEmailAndPassword(email, password).then(
       (userAuth) => {
         databaseRef.collection('users').doc(userAuth.user.uid)
         .set({
           id: userAuth.user.uid,
           email_address: email,
           name:
           {
              first_name: firstName,
              last_name: lastName
           },
           timestamp:{
             create_timestamp: moment(new Date()).format('h:mm a, MMM DD YYYY'),
             update_timestamp: moment(new Date()).format('h:mm a, MMM DD YYYY')
           },
           stats: {
             initial_amount: 10000,
             balance: 10000,
             total_trades: 0,
             verified_positive_trades: 0,
             verified_negative_trades: 0,
            // unverified_trades: 0,
             //consecutive_positive_trades: 0,
            // consecutive_negative_trades: 0,
            // longest_positive_trade: 0,
            // longest_negative_trade: 0,
           }
         });
         console.log("New User Created");
         console.log("User ID: ", userAuth.user.uid);
         debugger
         this.setState({ loading: false, user: userAuth});
       })
    )
  }

  async confirmResetPasswordAction(newPassword, code){
    this.setState({ loading: true});
    return (
       firebase.auth().confirmPasswordReset(code, newPassword).then(
       (userAuth) => {
         console.log("New Password was set");
         this.setState({ loading: false});
       })
    )
  }

   getTopProfitTraders = (resultsLimit) => {
        let headers = {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        };
        let body = JSON.stringify({
          numResults: resultsLimit,
        });
       return this.sendRequestToServer(
                    FIRESTORE_CONSTANTS.SERVER_URL,
                    'querytopbalanceusers',
                    'POST',
                    headers,
                    body
      );
  }
  async sendRequestToServer(URL, action, method, headers, body) {
    return fetch( URL+action, {
          method: method,
          headers: headers,
          body: body
       })
       .then(status)
       .then(json)
       .then(function(data) {
         console.log('Request succeeded with JSON response', data);
         return data;
       }).catch(function(error) {
         console.log('Request failed', error);
       });

    function status(response) {
      if (response.status >= 200 && response.status < 300) {
        return Promise.resolve(response)
      } else {
        return Promise.reject("Response Status: ", new Error(response.status))
      }
    }

    function json(response) {
      return response.json()
    }
  }

  render() {
    return (
      <UserContext.Provider value={this.state}>
        {!this.state.loading && this.props.children}
      </UserContext.Provider>
    );
  }
}
export default UserProvider;
