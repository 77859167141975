import React, { useContext }  from 'react'
import { Route, Redirect } from 'react-router-dom'
import { UserContext } from "providers/UserProvider.js";


export default function PrivateRoute ({component: Component, ...rest }) {

  const userProviderState = useContext(UserContext);
  const userPath = new RegExp('/*\/user\/*/');
  const traderProfile = new RegExp('/*\/admin\/trader-profile\/*/');
  const homepage = new RegExp('/$|\/$|\/\?/');

   return (
     <Route
     {...rest}
     render = { props => {
       debugger
       if (userProviderState.user){
            debugger
            if (userPath.test(props.location.pathname) ){
                 debugger
              return <Redirect from="/user/:id" to={"/admin/trader-profile/" + props.match.params.id}/>
            } else if (traderProfile.test(props.location.pathname) ){
                 debugger
                 //props.history.push("/admin/trader-profile/");
                 return <Redirect from="/admin/trader-profile/:id" to={{
                 pathname: "/admin/trader-profile/",
                 state: { id: props.match.params.id }
               }}   />
            // }
          } else
             //return <Redirect to={"/admin/my-profile/" + userProviderState.user.uid}/>
             return <Redirect to={"/admin/front-page/"}/>
       } else if (userPath.test(props.location.pathname) ){
            debugger
         return <Redirect from="/user/:id" to={"/admin/trader-profile/" + props.match.params.id}/>
       } else if (traderProfile.test(props.location.pathname) ){
            debugger
            //props.history.push("/admin/trader-profile/");
            return <Redirect from="/admin/trader-profile/:id" to={{
            pathname: "/admin/trader-profile/",
            state: { id: props.match.params.id }
          }}   />
       // }
     } else if (homepage.test(props.location.pathname)){
       debugger
        return <Redirect to={"/admin/front-page/"}/>
     }
       else {
        debugger
        return <Redirect to="/auth/login"/>
      }
       //return userProviderState.user ? <Redirect to={"/admin/my-profile/" + userProviderState.user.uid}/> : <Redirect to="/auth/login"/>
     }}
     >
     </Route>
   )
}
