

import React from "react";
import UserProvider from "providers/UserProvider";
import firebase from "firebaseConfig";

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardSubtitle,
  Row,
  Col,
  Table,
} from "reactstrap";

import {
  Link,
} from "react-router-dom"

const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];


var databaseRef = firebase.firestore();
var docData = null;

class LeaderBoard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      stats: {
        name: "",
        initial_amount: 10000,
        balance: "",
        totaltrades: 0,
        profit: "",

      },
    };
  }

    componentDidMount(){
      new UserProvider().getTopProfitTraders(4)
      .then( (topUsers) => {
        debugger
         this.setState(
               {
                  data: topUsers.map((prop, key) => {
                                     return {
                                        rank: key,
                                        name: prop.lastName + ", " + prop.firstName,
                                        emailAddress: prop.emailAddress,
                                        totalTrades: prop.totalTrades,
                                        profit: prop.balance - prop.initialAmount,
                                      };
                                    }),
               }
          )
    }).catch((error) => {
      debugger
        console.log("Error top users: ", error);
    });

    }

  render() {

    return (
      <>
        <div className="content">
            <Row>
            <a id="leaderboard">
              <Col md="12">
                <Card>
                    <CardHeader>
                      <CardTitle tag="h2"  className="title text-center trader-text-purple">Our Monthly Champions</CardTitle>
                        <div className="separator">✻</div>
                      <CardSubtitle className="mb-2 text-center trader-text-green">
                        <p>SHOWCASE YOUR TRADING TALENT AND WIN MONEY!</p>
                      </CardSubtitle>
                    </CardHeader>
                    <CardBody>
                    <Row>

                          <Col lg="4" sm="6">
                            <Card style={{height:'98%'}}>
                              <CardHeader>
                                <CardTitle tag="h2"  className="title text-center">
                                 How To Participate
                                </CardTitle>
                              </CardHeader>
                              <CardBody>
                                <p> It's really simple! Register with Verify Trader. Enter your trade predictions for at least a month with minimum of X trade predictions and you will be automotically signed on for the competition based on your profit. </p>
                                <div className="text-center">
                                    <Link
                                    to={{
                                            pathname:"/auth/register"
                                             }}
                                      className="btn-round btn btn-primary btn-theme-purple"
                                      size="m" >
                                          Try It Now!
                                     </Link>
                                </div>
                              </CardBody>
                            </Card>
                          </Col>

                          <Col lg="8" sm="6">
                            <Card style={{height:'98%'}}>
                              <CardHeader>
                                <CardTitle tag="h2"  className="title text-center">
                                  {monthNames[(new Date()).getMonth()]} Leaderboard
                                </CardTitle>
                                </CardHeader>
                              <CardBody>
                                <Table responsive  className="-striped -highlight" >
                                  <thead>
                                      <tr>
                                          <th className="text-center">Rank</th>
                                          <th className="text-center">Name</th>
                                          <th className="text-center">Profit</th>
                                          <th className="text-center">Total Trades</th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                      {this.state.data.map((trader) => (
                                        <tr key={trader.rank}>
                                          <td className="text-center">{trader.rank + 1}</td>
                                          <td className="text-center">{trader.name}</td>
                                          <td className="text-center">{trader.profit}</td>
                                          <td className="text-center">{trader.totalTrades}</td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </Table>
                              </CardBody>
                            </Card>
                          </Col>
                    </Row>
                    </CardBody>
              </Card>
              </Col>
            </a>
            </Row>
        </div>
      </>
    );
  }
}
export default LeaderBoard;
