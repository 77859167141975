/*!

=========================================================
* Paper Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { UserContext } from "providers/UserProvider.js";
import   {GetMyTrades}   from "providers/TradesProvider.js";

import firebase from "firebaseConfig";
import moment from 'moment';

// reactstrap components
import {
  Badge,
  //Button,
  Card,
  //CardHeader,
  CardBody,
  //CardFooter,
  //CardTitle,
  //FormGroup,
  //Form,
  //Input,
  Table,
  Row,
  Col,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  UncontrolledTooltip,
} from "reactstrap";

//import trader_profile_avatar from	 "assets/img/faces/ayo-ogunseinde-2.jpg";
// core components
import ReactTable from "components/ReactTable/ReactTable.js";
//import { Line, Bar, Pie } from "react-chartjs-2";

/* const dataTable = [
  ["AT&T - T", "29.4", "BUY", "30.12", "28.98", "Close of today", null],
  ["BOEING - BA", "242", "BUY", "252", "230", "Next week Friday", null],
  ["E-MINI S&P 500 - ESM21", "3958", "SELL", "3952", "3967", "Close of today", true],
  ["CRUDE OIL - CL", "60.7", "SELL", "60", "61.1", "First 2 hours of the open", true],
  ["10 YERAR TREASURY NOTE - TYM21", "131.02", "BUY", "131.04", "131.31", "Close of today",false],
  ["U.S GOVT BOND (JUNE CONTRACT) - ZBM21", "156.02", "BUY", "161.16", "155.25", "Close of Friday", false],
  ["FACEBOOK - FB", "270", "BUY", "278", "265", "Close of Thursday", true],
  ["APPLE COMPANY - APPL", "125", "SELL", "119", "128", "Close of Friday", false],
  ["TESLA COMPANY - TSLA", "730", "SELL", "610", "755", "Two weeks from entry", true],
  ["PFIZER COMPANY - PFE", "36.17", "BUY", "60", "61.1", "Either Stop or Target", true],
  ["MINI DOW (JUNE CONTRACT) - YM21", "33110", "SELL", "33050", "33130", "Close of today", true],
  ["WHEAT (MAY CONTRACT) - ZWK21", "602", "BUY", "609", "598.5", "Two weeks from entry", true],
];*/

//var trader_name = "Def Ault";
var trader_photo = require("assets/img/default-avatar.png");
var databaseRef = firebase.firestore();
var docData = null;

class TraderProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      trader_firstName: "Def",
      trader_lastName: "Ault",
      trader_userID: "",
      //alert: null,
      // data: dataTable.map((prop, key) => {
      //   return {
      //     id: key,
      //     symbol: prop[0],
      //     entryprice: prop[1],
      //     trade: prop[2],
      //     target: prop[3],
      //     stoploss: prop[4],
      //     window: prop[5],
      //     status: prop[6],
      //
      //   };
      // }),
      horizontalTabs: "trades",
      trader_photo: require("assets/img/default-avatar.png"),
      stats: {
        initial_amount: 10000,
        balance: 10000,
        total_trades: 0,
        verified_positive_trades: 0,
        verified_negative_trades: 0,
        //unverified_trades: 0,
        //consecutive_positive_trades: 0,
      //  consecutive_negative_trades: 0,
      //  longest_positive_trade: 0,
      //  longest_negative_trade: 0,
      },
      data: [],
    };
   debugger


    //trader_name = this.props.location.state.trader_name;
    //trader_photo = this.props.location.state.trader_photo;


  }
  static contextType = UserContext
  componentDidMount(){
    const userRef = this.context
    const { history } = this.props;


    if(userRef.user == null && typeof(this.props.location.state) == 'undefined' ){
      console.log("User is logged out")
      if(history) history.push("/auth/login")
    } else if(userRef.user != null && typeof(this.props.location.state) == 'undefined') {
          history.push("/admin/my-profile")
    } else {
            if(typeof(this.props.location.state.id) == 'undefined')
                history.push("/admin/my-profile")

            this.setState({
                  trader_userID:  this.props.location.state.id
                })
            databaseRef.collection('users').doc(this.props.location.state.id).get().then(
                    (userRecord) => {
                      debugger
                        if (userRecord.exists) {
                            debugger;
                            docData = userRecord.data();
                            console.log("Document data:", docData);
                            this.setState({
                                            trader_firstName: docData.name.first_name,
                                            trader_lastName:  docData.name.last_name,
                                            stats:{
                                              initial_amount: docData.stats.initial_amount,
                                              balance: docData.stats.balance,
                                              total_trades: docData.stats.total_trades,
                                              verified_positive_trades:  docData.stats.verified_positive_trades,
                                              verified_negative_trades:  docData.stats.verified_negative_trades,
                                            //  unverified_trades:  docData.stats.unverified_trades,
                                            //  consecutive_positive_trades:  docData.stats.consecutive_positive_trades,
                                            //  consecutive_negative_trades:  docData.stats.consecutive_negative_trades,
                                            //  longest_positive_trade:  docData.stats.longest_positive_trade,
                                            //  longest_negative_trade:  docData.stats.longest_negative_trade
                                            }
                                              //trader_photo =
                                          });

                        } else {
                            // docData will be undefined in this case
                            console.log("No such document!");
                            if(history) history.push("/auth/login")
                        }
                    }
                  ).catch((error) => {
                      console.log("Error getting document:", error);
                  });
                debugger
                console.log("this user_ID " + this.state.trader_userID);

                debugger
                //new TradesProvider().getMyTrades(
                   GetMyTrades(
                                  this.props.location.state.id,
                                  "time"
                                ).then( (userTrades) => {
                                    debugger
                                    this.setState(
                                          {
                                             data: userTrades.trades.map((prop, key) => {
                                                                return {
                                                                   id: key,
                                                                   timestamp_created: moment(new Date(prop.updateTimeStamp)).format('h:mm a, MMM DD YYYY'),
                                                                   symbol: prop.strategy.instrument,
                                                                   entryprice: prop.trade.entryPrice,
                                                                   trade: prop.trade.type,
                                                                   target: prop.trade.profitTarget,
                                                                   stoploss: prop.trade.stopLoss,
                                                                   tradeprofit: prop.trade.tradeProfit,
                                                                   tradevolume: prop.trade.tradeVolume,
                                                                 //  strategy:prop.trade.strategy,
                                                                   window: prop.trade.validUntil ? moment(new Date(prop.trade.validUntil._seconds * 1000)).format('h:mm a, MMM DD YYYY') : "", //new Date(prop.trade.validUntil._seconds * 1000).toLocaleString()
                                                                   status: prop.status,
                                                                 };
                                                               }),
                                          }
                                     )
                                     debugger
                                  }).catch((error) => {
                                       console.log("Error trades: ", error);
                                   });

        }
}
  //
  // getTrProps = (rowInfo, e) => {
  //   debugger
  //   this.setState( prevState => ({
  //         alert: (
  //           <SweetAlert
  //           type={rowInfo.row.values.status == null
  //                 ? 'warning'
  //                 : rowInfo.row.values.status == true
  //                          ? 'success'
  //                          : 'error'
  //                 }
  //            title={`Trade Details`} onConfirm={this.hideAlert}>
  //               <Card>
  //                 <CardBody className="text-left">
  //                 <Row>
  //                     <Col sm="6">
  //                         <Badge color="danger" pill>
  //                           Financial Instrument
  //                         </Badge>
  //                     </Col>
  //                     <Col sm="6">
  //                         {rowInfo.row.values.symbol}
  //                     </Col>
  //                 </Row>
  //               </CardBody>
  //               </Card>
  //
  //               <Card>
  //                 <CardBody className="text-left">
  //                 <Table responsive>
  //                   <thead>
  //                     <tr>
  //                       <th>
  //                           TRADE
  //                       </th>
  //                       <th>
  //                           ENTRY PRICE
  //                       </th>
  //                       <th>
  //                           STOP LOSS
  //                       </th>
  //                       <th>
  //                         TARGET
  //                       </th>
  //                     </tr>
  //                   </thead>
  //                   <tbody>
  //                     <tr>
  //                       <td>  {rowInfo.row.values.trade}</td>
  //                       <td>  {rowInfo.row.values.entryprice}</td>
  //                       <td>  {rowInfo.row.values.stoploss}</td>
  //                       <td>  {rowInfo.row.values.target}</td>
  //                     </tr>
  //                   </tbody>
  //                 </Table>
  //
  //                   </CardBody>
  //               </Card>
  //
  //               <Card>
  //                 <CardBody className="text-left">
  //                 <Row>
  //                   <Col sm="6">
  //                       <Badge color="success" pill>
  //                         Window
  //                       </Badge>
  //                   </Col>
  //                   <Col sm="6">
  //                         {rowInfo.row.values.window}
  //                   </Col>
  //                 </Row>
  //                   </CardBody>
  //               </Card>
  //               <Card>
  //                 <CardBody className="text-left">
  //                 <Row>
  //                   <Col sm="6">
  //                       <Badge color="info" pill>
  //                         Notes
  //                       </Badge>
  //                   </Col>
  //                   <Col sm="6">
  //                       something
  //                   </Col>
  //                 </Row>
  //                   </CardBody>
  //               </Card>
  //               <Card>
  //                 <CardBody className="text-left">
  //                 <Row>
  //                   <Col sm="6">
  //                       <Badge color="warning" pill>
  //                        Chart
  //                       </Badge>
  //                   </Col>
  //                   <Col sm="6">
  //                    <div className="fileinput thumbnail">
  //                     <div className="picture-container">
  //                       <img
  //
  //                         className="picture-src"
  //                         alt="..."
  //                       />
  //                     </div>
  //                     </div>
  //                   </Col>
  //                 </Row>
  //                   </CardBody>
  //               </Card>
  //
  //           </SweetAlert>
  //         ),
  //
  //       }))
  // }
  //
  // hideAlert = () => {
  //   this.setState({
  //     alert: null,
  //   });
  // };

  render() {
    return (
      <>
        <div className="content">
          {this.state.alert}
          <Row>
            <Col>
              <Card>
                <CardBody>
                <Row>
                  <Col md="3">
                  <div className="card-user trader">
                    <a onClick={(e) => e.preventDefault()} href>
                      <img
                        alt={this.state.trader_firstName + " " + this.state.trader_lastName}
                        className="trader_profile_avatar"
                        src={trader_photo}
                      />
                      <h6 className="title">{this.state.trader_firstName + " " + this.state.trader_lastName}</h6>
                    </a>
                  </div>
                  </Col>
                  <Col md="5">
                    <h6 className="title trader-text-purple">
                      TRADER PROFILE
                    </h6>
                  <p className="text-muted">
                   See audited trades and performance reports
                  </p>
                  </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <div className="nav-tabs-navigation">
                    <div className="nav-tabs-wrapper">
                      <Nav id="tabs" role="tablist" tabs>

                        <NavItem>
                          <NavLink
                            aria-expanded={
                              this.state.horizontalTabs === "trades"
                            }
                            data-toggle="tab"
                            role="tab"
                            className={
                              this.state.horizontalTabs === "trades"
                                ? "active"
                                : ""
                            }
                            onClick={() =>
                              this.setState({ horizontalTabs: "trades" })
                            }
                          >
                            <div className="title">
                              TRADES
                            </div>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            aria-expanded={this.state.horizontalTabs === "reports"}
                            data-toggle="tab"
                            role="tab"
                            className={
                              this.state.horizontalTabs === "reports"
                                ? "active"
                                : ""
                            }
                            onClick={() =>
                              this.setState({ horizontalTabs: "reports" })
                            }
                          >
                            <div className="title">
                              STATS
                            </div>
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </div>
                  </div>
                  <TabContent
                    className="text-center"
                    id="my-tab-content"
                    activeTab={this.state.horizontalTabs}
                  >
                    <TabPane tabId="reports" role="tabpanel">




                    <Row>
                      <Col className="ml-auto mr-auto" >
                      <Card>
                          {/* <CardHeader>
                            <CardTitle tag="h4">Stats</CardTitle>
                            </CardHeader>
                            */}
                            <CardBody>
                              <Row>

                                  <Table responsive>

                                  <thead className="text-muted">
                                    <tr>
                                      <th className="text-center">
                                       <h5>
                                        <Badge color="primary" pill>
                                          TOTAL TRADES
                                        </Badge>
                                        </h5>
                                      </th>
                                      <th className="text-center">
                                        Verified Positve Trades
                                      </th>
                                      <th className="text-center">
                                        VERIFIED NEGATIVE TRADES
                                      </th>
                                      <th className="text-center">
                                        UNVERIFIED TRADES
                                      </th>
                                    </tr>
                                  </thead>
                                    <tbody>
                                      <tr>
                                        <td className="text-center" ><h5> <b>{this.state.stats.total_trades}</b></h5></td>
                                        <td className="text-center">
                                          <h5>
                                            <Badge color="success" >{this.state.stats.verified_positive_trades}</Badge>
                                          </h5>
                                        </td>
                                        <td className="text-center">
                                          <h5>
                                            <Badge color="danger" pill>{this.state.stats.verified_negative_trades}   </Badge>
                                          </h5>
                                        </td>
                                        <td className="text-center"><b>{this.state.stats.unverified_trades}</b></td>
                                      </tr>
                                    </tbody>

                                      {/*}
                                    <tr>
                                      <td>

                                      </td>
                                      <td>
                                        <h5>
                                         <Badge color="primary" pill>
                                         TOTAL TRADES
                                         </Badge>
                                         </h5>
                                         <h5> <b>5876</b></h5>
                                       </td>
                                    <td>
                                      <b>POSITIVE VERIFIED TRADES</b>
                                      <h5>
                                        <Badge color="success" >3567</Badge>
                                      </h5>
                                    </td>
                                    <td>
                                      <b>NEGATIVE VERIFIED TRADES</b>
                                      <h5>
                                        <Badge color="danger" >2290</Badge>
                                      </h5>
                                    </td>
                                    <td>
                                      <b> UNVERIFIED TRADES</b>
                                      <h6>
                                        19
                                      </h6>
                                    </td>
                                    </tr>
                                    */}
                                  </Table>

                              </Row>
                              <Row>

                                  <Table responsive>
                                {/*
                                  <tbody>
                                    <tr>
                                      <td className="text-center">
                                      <Card>
                                        <CardTitle>
                                          CONSECUTIVE POSITIVE TRADES
                                        </CardTitle>
                                        <CardBody className="text-center">
                                        <h5>
                                          <Badge color="success" >115</Badge>
                                        </h5>
                                        </CardBody>
                                      </Card>

                                      </td>
                                      <td className="text-center">
                                      <Card>
                                        <CardTitle>
                                          CONSECUTIVE NEGATIVE TRADES
                                        </CardTitle>
                                        <CardBody className="text-center">
                                        <h5>
                                          <Badge color="danger" pill>39  </Badge>
                                        </h5>
                                        </CardBody>
                                      </Card>
                                      </td>
                                      <td className="text-center">
                                      <Card>
                                        <CardTitle>
                                          LONGEST POSITIVE TRADE
                                        </CardTitle>
                                        <CardBody className="text-center">
                                        <h5>46 hrs  </h5>
                                        </CardBody>
                                      </Card>
                                      </td>
                                      <td className="text-center">
                                      <Card>
                                        <CardTitle>
                                          LONGEST NEGATIVE TRADE
                                        </CardTitle>
                                        <CardBody className="text-center">
                                        <h5>28 hrs  </h5>
                                        </CardBody>
                                      </Card>
                                       </td>
                                    </tr>
                                  </tbody>
                                  */}
                                  {/*
                                  <thead className="text-muted">
                                    <tr>
                                      <th className="text-center">
                                        CONSECUTIVE POSITIVE TRADES
                                      </th>

                                      <th className="text-center">
                                        CONSECUTIVE NEGATIVE TRADES
                                      </th>
                                      <th className="text-center">
                                        LONGEST POSITIVE TRADES
                                      </th>
                                      <th className="text-center">
                                        LONGEST NEGATIVE TRADES
                                      </th>
                                    </tr>
                                  </thead>
                                    <tbody>
                                      <tr>
                                        <td className="text-center">
                                        {this.state.stats.consecutive_positive_trades}

                                        </td>
                                        <td className="text-center">
                                        {this.state.stats.consecutive_negative_trades}
                                        </td>
                                        <td className="text-center">{this.state.stats.longest_positive_trade + " hrs"}</td>
                                        <td className="text-center"> {this.state.stats.longest_negative_trade + " hrs"} </td>
                                      </tr>
                                    </tbody>
                                    */}
                                  </Table>

                              </Row>
                            </CardBody>
                          </Card>
                            </Col>
                          </Row>
 {/*
                          <Row>
                            <Col md="6">
                              <Card className="card-chart">
                                <CardHeader>
                                  <CardTitle>{chartExample1.title}</CardTitle>
                                  <p className="card-category">{chartExample1.description}</p>
                                </CardHeader>
                                <CardBody>
                                  <Line
                                    data={chartExample1.data}
                                    options={chartExample1.options}
                                  />
                                </CardBody>
                                <CardFooter>
                                  <hr />
                                  <div className="stats">
                                    <i className="fa fa-history" />
                                    {chartExample1.statFooterText}
                                  </div>
                                </CardFooter>
                              </Card>
                            </Col>
                            <Col md="6">
                              <Card className="card-chart">
                                <CardHeader>
                                  <CardTitle>{chartExample9.title}</CardTitle>
                                  <p className="card-category">{chartExample9.description}</p>
                                </CardHeader>
                                <CardBody>
                                  <Line
                                    data={chartExample9.data}
                                    options={chartExample9.options}
                                  />
                                </CardBody>
                                <CardFooter>
                                  <hr />
                                  <div className="stats">
                                    <i className="fa fa-history" />
                                    {chartExample9.statFooterText}
                                  </div>
                                </CardFooter>
                              </Card>
                            </Col>
                             <Col md="6">

                              <Card className="card-chart">
                                <CardHeader>
                                  <CardTitle>{chartExample10.title}</CardTitle>
                                  <p className="card-category">{chartExample10.description}</p>
                                </CardHeader>
                                <CardBody>
                                  <Line
                                    data={chartExample10.data}
                                    options={chartExample10.options}
                                  />
                                </CardBody>
                                <CardFooter>
                                  <hr />
                                  <div className="stats">
                                    <i className="fa fa-history" />
                                    {chartExample10.statFooterText}
                                  </div>
                                </CardFooter>
                              </Card>

                            </Col>
                            <Col md="6">
                              <Card className="card-chart">
                                <CardHeader>
                                  <CardTitle>{chartExample11.title}</CardTitle>
                                  <p className="card-category">{chartExample11.description}</p>
                                </CardHeader>
                                <CardBody>
                                  <Line
                                    data={chartExample11.data}
                                    options={chartExample11.options}
                                  />
                                </CardBody>
                                <CardFooter>
                                  <hr />
                                  <div className="stats">
                                    <i className="fa fa-history" />
                                    {chartExample11.statFooterText}
                                  </div>
                                </CardFooter>
                              </Card>
                            </Col>
                            <Col md="6">
                              <Card className="card-chart">
                                <CardHeader>
                                  <CardTitle>{chartExample12.title}</CardTitle>
                                  <p className="card-category">{chartExample10.description}</p>
                                </CardHeader>
                                <CardBody>
                                  <Line
                                    data={chartExample12.data}
                                    options={chartExample12.options}
                                  />
                                </CardBody>
                                <CardFooter>
                                  <hr />
                                  <div className="stats">
                                    <i className="fa fa-history" />
                                    {chartExample12.statFooterText}
                                  </div>
                                </CardFooter>
                              </Card>
                            </Col>

                    </Row>
  */}

                    </TabPane>
                    <TabPane tabId="trades" role="tabpanel">
                    <Row>
                      <Col md="12">
                        <Card>
                          <CardBody>
                            <ReactTable
                              id="tradesTable"
                              data={this.state.data}
                              columns={[
                                {
                                  Header: "Date/Time",
                                  accessor: "timestamp_created",
                                  Cell: ({ cell }) => <div style={{whiteSpace: 'normal'}} >{cell.row.values.timestamp_created}</div>
                                },
                                {
                                  Header: "Symbol",
                                  accessor: "symbol",
                                  Cell: ({ cell }) => <div style={{whiteSpace: 'normal'}} >{cell.row.values.symbol}</div>
                                },
                                {
                                  Header: "Entry Price",
                                  accessor: "entryprice",
                                },
                                {
                                  Header: "Target",
                                  accessor: "target",
                                },
                                {
                                  Header: "Stop Loss",
                                  accessor: "stoploss",
                                },
                                {
                                  Header: "Profit",
                                  accessor: "tradeprofit",
                                },
                                {
                                  Header: "Trade",
                                  accessor: "trade",
                                },
                                // {
                                //   Header: "Strategy",
                                //   accessor: "strategy",
                                // },
                                {
                                  Header: "Valid Until",
                                  accessor: "window",
                                   Cell: ({ cell }) => <div style={{whiteSpace: 'normal'}} >{cell.row.values.window}</div>
                                },
                                {
                                  Header: "Status",
                                  accessor: "status",
                                  Cell:({ cell }) => (
                                    <>

                                    <i id="tooltip264453347" className={ cell.row.values.status == "recorded"
                                          ? "fa fa-question fa-2x"
                                          : ( ( cell.row.values.status == "close" && cell.row.values.tradeprofit > 0)
                                               ? "fa fa-check fa-2x"
                                               : "fa fa-times fa-2x") }

                                                style={ cell.row.values.status == "recorded"
                                                      ? {color:'grey'}
                                                      : ( (cell.row.values.status == "close" && cell.row.values.tradeprofit > 0)
                                                               ? {color:'green'}
                                                               : {color:'red'})
                                                      }

                                      />

                                    <UncontrolledTooltip
                                      delay={0}
                                      target="tooltip264453347"
                                    >
                                        Blockchain Verified
                                    </UncontrolledTooltip>
                                    </>
                                  )

                                },

                                // {
                                //   Header: "Actions",
                                //   accessor: "actions",
                                //   Cell:({ cell }) => (
                                //     <>
                                //     <Button
                                //       className="btn-icon"
                                //       color="info"
                                //       id="tooltip264453216"
                                //       size="sm"
                                //       type="button"
                                //       value={cell.row.values.name}
                                //       onClick={e => this.getTrProps(cell, e)}
                                //     >
                                //       <i className="fa fa-expand" />
                                //     </Button>{" "}
                                //     <UncontrolledTooltip
                                //       delay={0}
                                //       target="tooltip264453216"
                                //     >
                                //       Details
                                //     </UncontrolledTooltip>
                                //     </>
                                //   )
                                // }
                              ]}
                              /*
                                You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                              */
                              className="-striped -highlight primary-pagination"
                            />
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
            </Row>
        </div>
      </>
    );
  }
}
export default TraderProfile;
