/*!

=========================================================
* Paper Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React,  { useState } from "react";
import { NavLink } from "react-router-dom";
//import firebase from "firebaseConfig";
//import { useHistory } from "react-router-dom";

//import { UserContext } from "providers/UserProvider.js";
import  UserProvider  from "providers/UserProvider";

import {
  Alert,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Row,
} from "reactstrap";

const ForgotPassword = () => {
 //const user = useContext(UserContext);
 const [error, setError] = useState("")
 const [email, setEmail] = useState("");
 const [message, setMessage] = useState("");

 //const history = useHistory();

  const onChangeHandler = event => {
   const { name, value } = event.currentTarget;
   if (name === "userEmail") {
     setEmail(value);
   }
 };
 async function handleResetPassword(event) {
  try {
         debugger
         setMessage("")
         setError("")
         event.preventDefault()
         await new UserProvider().resetPasswordAction(email)
         setMessage('Reset Password instructions sent to your email')
         debugger

      } catch(error){
        debugger
      //  return alert(error)
         setError("Failed to reset password. " + error.message);
      }

 }
    return (
      <div className="login-page">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" lg="4" md="6">
              <Form action="" className="form" method="">
                <Card className="card-login">
                  <CardHeader>
                    <CardHeader>
                      <h3 className="header text-center">Reset Password</h3>
                    </CardHeader>
                  </CardHeader>
                  <CardBody>
                  {error && <Alert color = "danger">{error}</Alert>}
                  {message && <Alert color = "success">{message}</Alert>}
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="nc-icon nc-single-02" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input name="userEmail" placeholder="Email" type="email" onChange={event => onChangeHandler(event)} />
                    </InputGroup>

                    <FormGroup>
                      <NavLink to="/auth/login" className="nav-link">
                        Login
                      </NavLink>
                    </FormGroup>
                  </CardBody>
                  <CardFooter>
                    <Button
                      block
                      className="btn-round mb-3"
                      color="warning"
                      onClick={event => handleResetPassword(event)}
                    >
                      Reset Password
                    </Button>
                    <FormGroup>
                      Need an account? <NavLink to="/auth/register"> Resgister </NavLink>
                    </FormGroup>
                  </CardFooter>
                </Card>
              </Form>
            </Col>
          </Row>
        </Container>
        <div
        />
      </div>
    );

}


export default ForgotPassword;
