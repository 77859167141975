import * as firebase from "firebase";

//  databaseURL: "https://verifytrader.firebaseio.com",
//const firebase = require("firebase");
require("firebase/firestore");

// Required for side-effects
var config = {
    apiKey: "AIzaSyBjQ2XodaTkD0kGjvXTrfL-God4jq3RC0g",
    authDomain: "verifytrader.firebaseapp.com",
    projectId: "verifytrader",
    storageBucket: "verifytrader.appspot.com",
    messagingSenderId: "823943029892",
    appId: "1:823943029892:web:ae1c57a2e0dda755198ed1",
    measurementId: "G-GHM17RXWPC"
  };

  let firebaseConfig = firebase.initializeApp(config);

  export default firebaseConfig;
