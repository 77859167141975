import * as FIRESTORE_CONSTANTS from "constants/cloudfirestore";

// class TradesProvider extends Component {
//
//   constructor(props) {
//     super(props);
//   }
//
//   componentDidMount(){
//
//   };
//      render() {
//        return(
//          <div></div>
//        )
//      }
// }
  function ShareTradeRecord(userId){
       //debugger
        //e.preventDefault();
        let headers = {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        };
        let body = JSON.stringify({
          userId: userId,
        });
       return sendRequestToServer(
                    FIRESTORE_CONSTANTS.SERVER_URL,
                    'sharetraderecord',
                    'POST',
                    headers,
                    body
      );

    };

  function CreateTrade( userId, strategyname, instrument, entryprice,
                        target, stoploss, tradeprofit, tradevolume, validuntil){
        //window.alert('Create Trade');
        //e.preventDefault();
        let headers = {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        };
        let body = JSON.stringify({
          userId: userId,
          strategyName: strategyname,
          instrument: instrument,
          entryPrice: entryprice,
          profitTarget: target,
          stopLoss: stoploss,
          tradeProfit: tradeprofit,
          tradeVolume: tradevolume,
          validUntil: validuntil,

        });
      //  debugger
       return sendRequestToServer(
                    FIRESTORE_CONSTANTS.SERVER_URL,
                    'createtrade',
                    'POST',
                    headers,
                    body
      );

    };
    function DeleteTrade( userId, tradeId){
          let headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          };
          let body = JSON.stringify({
            userId: userId,
            tradeId: tradeId,
          });
        //  debugger
         return sendRequestToServer(
                      FIRESTORE_CONSTANTS.SERVER_URL,
                      'deletetrade',
                      'POST',
                      headers,
                      body
        );

      };

      function MarkToDeleteTrade( userId, tradeId){
            let headers = {
              Accept: 'application/json',
              'Content-Type': 'application/json'
            };
            let body = JSON.stringify({
              userId: userId,
              tradeId: tradeId,
            });
          //  debugger
           return sendRequestToServer(
                        FIRESTORE_CONSTANTS.SERVER_URL,
                        'marktodeletetrade',
                        'POST',
                        headers,
                        body
          );

        };
    function GetMyTrades( userId, orderBy, direction, numResults, resumeToken ){
      //   debugger
          //e.preventDefault();
          let headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          };
          let body = JSON.stringify({
            userId: userId,
            orderBy: orderBy,
            direction: direction,
            numResults: numResults,
            resumeToken: resumeToken,

          });
         return sendRequestToServer(
                      FIRESTORE_CONSTANTS.SERVER_URL,
                      'queryusertrades',
                      'POST',
                      headers,
                      body
        );

      };

  async function sendRequestToServer(URL, action, method, headers, body){
    //   debugger
    // const requestOptions = {
    //   method: method,
    //   headers: headers,
    //   body: body
    //  };
    // const response = await fetch( URL+action, requestOptions);
    // return await response.json();
  //window.alert("TradesProvider: SendRequestToServer ", URL+action);
    return fetch( URL+action, {
          method: method,
          headers: headers,
          body: body
       })
       .then(status)
       .then(json)
       .then(function(data) {
         console.log('Request succeeded with JSON response', data);
        //window.alert('Request succeeded with JSON response', data);
        // debugger
         return data;
       }).catch(function(error) {
         console.log('Request failed', error);
         //debugger
        //  window.alert('Request failed', error);
       });

    function status(response) {
      if (response.status >= 200 && response.status < 300) {
      //  debugger
      //  window.alert("Response Status", new Error(response.status));
        return Promise.resolve(response)
      } else {
      //  window.alert("Response Error", new Error(response.status));
        return Promise.reject("Response Status: ", new Error(response.status))
      }
    }

    function json(response) {
      //debugger
      //window.alert(response.json());
      return response.json()
    }
  }

export { ShareTradeRecord, GetMyTrades, CreateTrade, DeleteTrade, MarkToDeleteTrade};
//export default TradesProvider;
