/*!

=========================================================
* Paper Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import classnames from "classnames";
//import logoHalf from "assets/img/VerifyTraderLogoHalf.png";
import logoFull from "assets/img/FullLogoVerifyTrader.png";

// reactstrap components
import {
  //Collapse,
  NavbarBrand,
  Navbar,
  //NavItem,
  //Nav,
  Container,
} from "reactstrap";

class AuthNavbar extends React.Component {
  constructor(props) {
    debugger
    super(props);
    debugger
    this.state = {
      collapseOpen: false,
      color: "navbar-transparent",
    };
  }
  componentDidMount() {
    debugger
    window.addEventListener("resize", this.updateColor);
  }
  // this function opens and closes the collapse on small devices
  // it also adds navbar-transparent class to the navbar when closed
  // ad bg-white when opened
  toggleCollapse = () => {
    let newState = {
      collapseOpen: !this.state.collapseOpen,
    };
    if (!this.state.collapseOpen) {
      newState["color"] = "bg-white";
      debugger
    } else {
      newState["color"] = "navbar-transparent";
      debugger
    }
    this.setState(newState);
  };
  render() {
    return (

      <Navbar
        className={classnames("navbar-absolute fixed-top", this.state.color)}
        expand="lg"
      >
        <Container>
        <NavbarBrand href="/admin/front-page" >
          <span className="d-none d-md-block">
            <div className="logo-container-auth">
              <img src={logoFull} alt="v-logo" />
            </div>
          </span>
          <span className="d-block d-md-none">Trader Bureau</span>
        </NavbarBrand>
        {/*
          <button
            aria-controls="navigation-index"
            aria-expanded={false}
            aria-label="Toggle navigation"
            className="navbar-toggler"
            data-toggle="collapse"
            type="button"
            onClick={this.toggleCollapse}
          >
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
          </button>
          <Collapse
            isOpen={this.state.collapseOpen}
            className="justify-content-end"
            navbar
          >
            <Nav navbar>
              <NavItem>
                <NavLink to="/admin/dashboard" className="nav-link">
                  <i className="nc-icon nc-layout-11" />
                  Dashboard
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/auth/register" className="nav-link">
                  <i className="nc-icon nc-book-bookmark" />
                  Register
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/auth/login" className="nav-link">
                  <i className="nc-icon nc-tap-01" />
                  Login
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/admin/user-profile" className="nav-link">
                  <i className="nc-icon nc-satisfied" />
                  User
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/auth/lock-screen" className="nav-link">
                  <i className="nc-icon nc-key-25" />
                  Lock
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
          */}
        </Container>
      </Navbar>

    );
  }
}

export default AuthNavbar;
