/*!

=========================================================
* Paper Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React  from "react";
import ReactDOM from "react-dom";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";

import UserProvider from "providers/UserProvider.js";
import PrivateRoute from "views/components/PrivateRoute";
import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";
import MyProfile from "views/pages/MyProfile.js";
import FrontPage from "views/FrontPage.js";
import TraderProfile from "views/pages/TraderProfile.js";

import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/paper-dashboard.scss?v=1.2.0";
import "assets/css/custom.css";
import "assets/demo/demo.css";
import "perfect-scrollbar/css/perfect-scrollbar.css";

const hist = createBrowserHistory();

ReactDOM.render(
  <UserProvider>
    <App/>
  </UserProvider>,
   document.getElementById("root")
 )

 function App() {
     return (
           <Router history={hist}>
             <Switch>
              {/*<PrivateRoute exact path="/" component={Dashboard}/>*/}
                 <PrivateRoute exact path="/" component={FrontPage}/>
                 <PrivateRoute exact path="/user/:id" component={ (props) => <TraderProfile {...props} /> }/>
              {/*<PrivateRoute  path="/admin/trader-profile/:id" component={TraderProfile} />*/}
                 <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
                 <Route path="/admin" render={(props) => <AdminLayout {...props} />} />

                 <Redirect to="/admin/front-page" />
             </Switch>
           </Router>
         );
 }
 export default App;
