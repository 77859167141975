/*!

=========================================================
* Paper Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React,  { useState } from "react";
import { NavLink} from "react-router-dom";
//import firebase from "firebaseConfig";
import { useHistory } from "react-router-dom";

//import { UserContext } from "providers/UserProvider.js";
import  UserProvider  from "providers/UserProvider";
import bcrypt from 'bcryptjs';

import {
  Alert,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Row,
} from "reactstrap";


const Login = () => {
//const user = useContext(UserContext);
 const [error, setError] = useState("")
 const [email, setEmail] = useState("");
 const [password, setPassword] = useState("");
 const history = useHistory();

 // SALT should be created ONE TIME upon sign up
 const salt = "$2a$10$lrRPFX9uc1K8xinbXrxQju";//bcrypt.genSaltSync(10); //

  const onChangeHandler = event => {
   const { name, value } = event.currentTarget;
   if (name === "userEmail") {
     setEmail(value);
   } else if (name === "userPassword") {
      const hashedPassword = bcrypt.hashSync(value, salt)
      setPassword(hashedPassword);
       //setPassword(value);
   }
 };
 async function handleLogin(event) {
  try {
         debugger
         setError("")
         event.preventDefault()
         await new UserProvider().loginAction(email, password)
         debugger
         history.push("/admin/my-profile")
      } catch(error){
        debugger
      //  return alert(error)
         setError("Failed to Log In. " + error.message);
      }

 }
    return (
      <div className="login-page">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" lg="4" md="6">
                <Card className="card-login">
                  <CardHeader className="text-center">
                      <CardTitle tag="h4">Login</CardTitle>
                  </CardHeader>
                  <CardBody>
                  <Form action="" className="form" method="">
                  {error && <Alert color = "danger">{error}</Alert>}
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="nc-icon nc-single-02" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input name="userEmail" placeholder="Email" type="email" onChange={event => onChangeHandler(event)} />
                    </InputGroup>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="nc-icon nc-key-25" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input name="userPassword"
                        placeholder="Password"
                        type="password"
                        autoComplete="off"
                        onChange={event => onChangeHandler(event)}
                      />
                    </InputGroup>
                    <br />
                    <FormGroup>
                      <FormGroup check>
                        <Label check>
                          <Input
                            defaultChecked
                            defaultValue=""
                            type="checkbox"
                          />
                          <span className="form-check-sign" />
                          Remember Me
                        </Label>
                      </FormGroup>
                    </FormGroup>
                    <FormGroup>
                      <NavLink to="/auth/forgot-password" className="nav-link">
                        Forgot Password
                      </NavLink>
                    </FormGroup>
                    </Form>
                  </CardBody>
                  <CardFooter>
                    <Button
                      block
                      className="btn-round mb-3"
                      color="warning"
                      onClick={event => handleLogin(event)}
                    >
                      Login
                    </Button>
                    <FormGroup>
                      Need an account? <NavLink to="/auth/register"> Resgister </NavLink>
                    </FormGroup>
                  </CardFooter>
                </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );

}


export default Login;
