/*!

=========================================================
* Paper Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React,  { useState, useEffect, useCallback }  from "react";

import { useHistory } from "react-router-dom";
//import { UserContext } from "providers/UserProvider.js";
import  UserProvider  from "providers/UserProvider";
import bcrypt from 'bcryptjs';

// reactstrap components
import {
  Alert,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";


const Register = () => {

const [error, setError] = useState("")
const [isEmptyFields, setIsEmptyFields] = useState(true)
const [loading, setLoading] = useState(false)
const [termsConditionsCheck, setTermsAndConditionsCheck] = useState(true)
const [email, setEmail] = useState("");
const [firstName, setFirstName] = useState("");
const [lastName, setLastName] = useState("");
const [password, setPassword] = useState("");
const [passwordConfirm, setPasswordConfirm] = useState("");
const history = useHistory();

// SALT should be created ONE TIME upon sign up
const salt = "$2a$10$lrRPFX9uc1K8xinbXrxQju";//bcrypt.genSaltSync(10);

const requiredFieldsEmpty=useCallback(() => {
  if(firstName.length>0 && lastName.length>0 && email.length>0 && password.length > 0 && passwordConfirm.length > 0){
    setIsEmptyFields(false)
    return false
  }
  else {
    setIsEmptyFields(true)
    return true
  }
}, [firstName,lastName,email,password, passwordConfirm])

useEffect(() => {
    debugger
   //document.body.classList.toggle("register-page");
   //setRequiredFields(requiredFields());
   if(error.length>0)
     return;

   requiredFieldsEmpty()

   if(password !== passwordConfirm )
       setError("Passwords must match")
   else
      setError("")

},[password, passwordConfirm, loading, isEmptyFields, error, requiredFieldsEmpty])

const validateName = (event) => {
  event.preventDefault();
  const { name, value } = event.currentTarget;
  const re = /^[A-Za-z,.'-]+$/i;

  debugger
  if ( re.test(String(value)) && value.length > 0){
    setError("")
    if (name === "firstName")
      setFirstName(value)
    if (name === "lastName")
      setLastName(value)
    return true
  }
  else {
    setError("Invalid Name")
    return false
  }

}
const validateEmail = (event) => {
  event.preventDefault();
  debugger
  const { value } = event.currentTarget;
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(String(value).toLowerCase()) && value.length > 0){
      setError("")
      setEmail(value)
      return true
    }
    else {
      setError("Invalid Email")
      return false
    }
}
const validatePassword = (event) => {
  event.preventDefault();
  const { name, value } = event.currentTarget;
  const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{5,}$/;
  if (re.test(String(value))  && value.length > 0 ){
    debugger
    setError("")
    if (name === "password"){
      const hashedPassword = bcrypt.hashSync(value, salt)
      //console.log("Salt is " + salt);
       setPassword(hashedPassword);
        //setPassword(value);
    }
    if (name === "passwordConfirm"){
       const hashedPassword = bcrypt.hashSync(value, salt)
      // console.log("Salt is " + salt);
       setPasswordConfirm(hashedPassword);
        //setPasswordConfirm(value);
    }
    return true
  }
  else {
    debugger
    setError("Invalid Password: Minimum 5 characters, at least one uppercase letter, one lowercase letter and one number:")
    return false
  }
}

   async function handleRegister(event){
    //event.preventDefault()
    debugger;
    if(error.length>0)
      return;
    if(requiredFieldsEmpty())
      return setError("Please fill in all required fields");

    debugger
    setError("")
    setLoading(true)
    await new UserProvider().signUpAction(email, password, firstName, lastName)
                           .then(data =>
                           {
                             debugger
                             history.push("/admin/my-profile")
                             setLoading(false)
                           })
                           .catch( err =>
                           {
                               debugger
                               return setError(err.message);
                           })                
                              setLoading(false)
  }
    return (
      <div className="register-page">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" lg="4" md="6">
              <Card className="card-signup text-center">
                <CardHeader>
                  <CardTitle tag="h4">Register</CardTitle>
                  {/*
                  <div className="social">
                    <Button className="btn-icon btn-round" color="twitter">
                      <i className="fa fa-twitter" />
                    </Button>
                    <Button className="btn-icon btn-round" color="dribbble">
                      <i className="fa fa-dribbble" />
                    </Button>
                    <Button className="btn-icon btn-round" color="facebook">
                      <i className="fa fa-facebook-f" />
                    </Button>
                    <p className="card-description">or be classical</p>
                  </div>
                  */}
                </CardHeader>
                <CardBody>
                  <Form action="" className="form" method="">
                    {error && <Alert color = "danger">{error}</Alert>}
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="nc-icon nc-single-02" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input name="firstName" placeholder="First Name..." type="text"
                      onChange={e=>validateName(e)} />
                    </InputGroup>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="nc-icon nc-circle-10" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input name="lastName" placeholder="Last Name..." type="text"
                      onChange={e=>validateName(e)} />
                    </InputGroup>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="nc-icon nc-email-85" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input name="email" placeholder="Email..." type="email"
                      onChange={e=>validateEmail(e)} />
                    </InputGroup>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="nc-icon nc-circle-10" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input name="password" placeholder="Password" type="password"
                      onChange={e=>validatePassword(e)} />
                    </InputGroup>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="nc-icon nc-circle-10" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input name="passwordConfirm" placeholder="PasswordConfirm" type="password"
                      onChange={e=>validatePassword(e)} />
                    </InputGroup>
                    <FormGroup check className="text-left">
                      <Label check>
                        <Input name="TermsAndConditions" defaultChecked type="checkbox"
                                  onChange={() => setTermsAndConditionsCheck(!termsConditionsCheck)} />
                        <span className="form-check-sign" />I agree to the{" "}
                        <a href="" onClick={(e) => e.preventDefault()}>
                          terms and conditions.
                        </a>

                      </Label>
                    </FormGroup>
                  </Form>
                </CardBody>
                <CardFooter>
                  <Button
                    disabled={loading || error.length>0 || isEmptyFields || !termsConditionsCheck}
                    className="btn-round"
                    color="info"
                    //href="#pablo"
                    onClick={event => handleRegister(event)}
                  >
                    Get Started
                  </Button>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </Container>
        {/*
        <div
          className="full-page-background"
          style={{
            backgroundImage: `url(${require("assets/img/bg/jan-sendereks.jpg")})`,
          }}
        />
        */}
      </div>
    );

}

export default Register;
