

import React from "react";

import {
  Card,
  CardHeader,
  CardBody,
  CardImg,
  CardImgOverlay,
  CardLink,
  CardTitle,
  CardText,
  CardSubtitle,
  Row,
  Col,
} from "reactstrap";

import {
  Link,
} from "react-router-dom"

class FrontPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      singleSelect: null,
      multipleSelect: null,
      firstName: "",
      lastName: "",
      email: "",
    };
    debugger;

  }

  render() {

    return (
      <>
        <div className="content">
          {this.state.alert}

  <Row>
    <Col md="12">
      <Card className="bg-dark text-white">
       <CardImg src={require("assets/img/bw_hand.jpeg")} alt="..."/>
      {/*
        <CardHeader>
          <CardTitle tag="h2"  className="title text-center" >Our mission is to help build credibility and confidence for traders</CardTitle>
        </CardHeader>
        */}
       <CardImgOverlay>
        <CardBody>
          <Row style={{ position: 'absolute',
                        top: '30%' }}>
          <Col md="6" className="text-center">
          {/*
            <h6>
              Our mission is to help build credibility and confidence for traders.
            </h6>
            */}
            <CardTitle tag="h2"  className="title">
              Audit trades and build certified track records
            </CardTitle>
            <CardText>
              Our mission is to help build credibility and confidence for traders
            </CardText>
            <Link
            to={{
                    pathname:"/auth/register"
                     }}
              className="btn-round btn btn-primary btn-theme-purple"
              size="m" >
                  Register Now!
             </Link>
             <p>
              <CardLink href="#whatwedo">See More Details</CardLink>
             </p>
          </Col>
          {/*
            <Col md="6">
              <h6>
                BUILT CREDIBLE TRACK RECORD, THIRD PARTY VERIFIED AND IMMUTABLE
              </h6>
          <p className="text-muted">
          Traders can audit their trades, build track records and get certification on validity of their record.
            </p>
            <br/>
              <h6>
              COMPLETE REPORT WITH MARKET CONDITIONS AND MENTORSHIP
              </h6>
            <p className="text-muted">
             Our solution will help evaluate performance, examine the efficiency and effectiveness with the goal of implementing improvements, keep the trail of your trades in the standardized format.
            </p>
            </Col>
            */}
            <Col className="ml-auto mr-auto" md="6">
            {/*
              <div className="image">
                <img
                  alt="..."
                  src={require("assets/img/bw_hand_2fingers.png")}
                />
              </div>
              */}


            </Col>
          </Row>
        </CardBody>
        </CardImgOverlay>
      </Card>
    </Col>
  </Row>

  <Row>
  <a id="whatwedo">
    <Col md="12">
      <Card>
          <CardHeader>
            <CardTitle tag="h2"  className="title text-center">What We Do</CardTitle>
              <div className="separator separator-danger">✻</div>
            <CardSubtitle className="mb-2 text-center">
              <p>WE DISCOVER THE NEXT GENERATION OF TRADING TALENT!</p>
            </CardSubtitle>
          </CardHeader>
          <CardBody>
          <Row>
                <Col lg="4" sm="6">
                    <Card style={{height:'98%'}}>
                      <h1 className="text-center trader-text-purple">
                      <i className="nc-icon nc-badge"></i>
                      </h1>
                      <CardHeader>
                        <CardTitle tag="h2"  className="title text-center">
                         Build Credibility
                        </CardTitle>
                      </CardHeader>
                      <CardBody>
                        <p> Our system helps traders establish trust with their investors. Traders can build credible trade records with our system. Traders get certification on validity of their record.</p>
                      </CardBody>
                    </Card>
                </Col>

                <Col lg="4" sm="6">
                  <Card style={{height:'98%'}}>
                    <h1 className="text-center trader-text-purple">
                    <i className="nc-icon nc-check-2"></i>
                    </h1>
                    <CardHeader>
                      <CardTitle tag="h2"  className="title text-center">
                       Audit and Verify
                      </CardTitle>
                    </CardHeader>
                    <CardBody>
                      <p> Submit your trade predictions to our platform before they happen, we lock your trade predictions and verify them
                         with realtime market data. We build trading data records that are easy to follow, digest and share.
                         We treat your trade record as strictly confidential information. We will not disclose or share your trade record with any third parties without your explicit consent.</p>
                    </CardBody>
                  </Card>
                </Col>

                <Col lg="4" sm="6">
                  <Card style={{height:'98%'}}>
                    <h1 className="text-center trader-text-purple">
                    <i className="nc-icon nc-bulb-63"></i>
                    </h1>
                    <CardHeader>
                      <CardTitle tag="h2"  className="title text-center">
                        Find an Edge
                      </CardTitle>
                      </CardHeader>
                    <CardBody>
                      <p> Discover trading strategies that work. Find an edge, diversify your portfolio. Our system provides traders with a way to test any trading idea for various markets through forward perfomance trading.</p>
                    </CardBody>
                  </Card>
                </Col>
          </Row>

          <Card className="text-center">
            <CardBody>
              <Link
              to={{
                      pathname:"/auth/register"
                       }}
                className="btn-round btn btn-primary btn-theme-purple"
                size="m" >
                    Try It Now!
               </Link>
              </CardBody>
            </Card>
            </CardBody>
    </Card>
    </Col>
  </a>
  </Row>
  <Row>
  </Row>
</div>
      </>
    );
  }
}
export default FrontPage;
