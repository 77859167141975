/*!

=========================================================
* Paper Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
const Chart = require("chart.js");

Chart.pluginService.register({
  beforeDraw: function (chart) {
    if (chart.config.options.elements.center) {
      //Get ctx from string
      var ctx = chart.chart.ctx;

      //Get options from the center object in options
      var centerConfig = chart.config.options.elements.center;
      var fontStyle = centerConfig.fontStyle || "Arial";
      var txt = centerConfig.text;
      var color = centerConfig.color || "#000";
      var sidePadding = centerConfig.sidePadding || 20;
      var sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2);
      //Start with a base font of 30px
      ctx.font = "30px " + fontStyle;

      //Get the width of the string and also the width of the element minus 10 to give it 5px side padding
      var stringWidth = ctx.measureText(txt).width;
      var elementWidth = chart.innerRadius * 2 - sidePaddingCalculated;

      // Find out how much the font can grow in width.
      var widthRatio = elementWidth / stringWidth;
      var newFontSize = Math.floor(30 * widthRatio);
      var elementHeight = chart.innerRadius * 2;

      // Pick a new font size so it will not be larger than the height of label.
      var fontSizeToUse = Math.min(newFontSize, elementHeight);

      //Set font settings to draw it correctly.
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      var centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
      var centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;
      ctx.font = fontSizeToUse + "px " + fontStyle;
      ctx.fillStyle = color;

      //Draw text in center
      ctx.fillText(txt, centerX, centerY);
    }
  },
});

// default color for the charts
let chartColor = "#FFFFFF";
// ##############################
// // // Function that converts a hex color number to a RGB color number
// #############################
const hexToRGB = (hex, alpha) => {
  var r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
  } else {
    return "rgb(" + r + ", " + g + ", " + b + ")";
  }
};

// ##############################
// // // Chart variables
// #############################

// #########################################
// // // used inside src/views/Dashboard.js and src/views/Charts.js
// #########################################
const chartExample1 = {
  title: "Growth",
  description: "",
  statFooterText: "View Details",
  data: {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
    ],
    datasets: [
      {
        label: "Growth percentage",
        borderColor: "#6bd098",
        pointRadius: 0,
        pointHoverRadius: 0,
        fill: false,
        borderWidth: 3,
        barPercentage: 1.6,
        data: [14, 18, 33, 45, 55, 33, 45, 38, 44, 77, 66, 81],
      },
    ],
  },
  options: {
    legend: {
      display: false,
    },

    tooltips: {
      enabled: false,
    },

    scales: {
      yAxes: [
        {
          ticks: {
            fontColor: "#9f9f9f",
            beginAtZero: false,
            maxTicksLimit: 5,
            //padding: 20
          },
          gridLines: {
            drawBorder: false,
            zeroLineColor: "transparent",
            color: "rgba(255,255,255,0.05)",
          },
        },
      ],

      xAxes: [
        {
          gridLines: {
            drawBorder: false,
            color: "rgba(255,255,255,0.1)",
            zeroLineColor: "transparent",
            display: false,
          },
          ticks: {
            padding: 20,
            fontColor: "#9f9f9f",
          },
        },
      ],
    },
  },
};

// #########################################
// // // used inside src/views/Dashboard.js
// #########################################
const chartExample2 = {
  title: "24 Hours Performance",
  description: "Line Chart",
  statFooterText: "View Details",
  data: (canvas) => {
    let ctx = canvas.getContext("2d");

    let gradientStroke = ctx.createLinearGradient(500, 0, 100, 0);
    gradientStroke.addColorStop(0, "#18ce0f");
    gradientStroke.addColorStop(1, chartColor);

    let gradientFill = ctx.createLinearGradient(0, 170, 0, 50);
    gradientFill.addColorStop(0, "rgba(128, 182, 244, 0)");
    gradientFill.addColorStop(1, hexToRGB("#18ce0f", 0.4));
    return {
      labels: ["12pm", "3pm", "6pm", "9pm", "12am", "3am", "6am", "9am"],
      datasets: [
        {
          label: "Email Stats",
          borderColor: "#ef8156",
          pointHoverRadius: 0,
          pointRadius: 0,
          fill: false,
          backgroundColor: gradientFill,
          borderWidth: 3,
          barPercentage: 1.6,
          data: [40, 500, 650, 700, 1200, 1250, 1300, 1900],
        },
      ],
    };
  },
  options: {
    legend: {
      display: false,
    },
    tooltips: {
      enabled: false,
    },
    scales: {
      yAxes: [
        {
          ticks: {
            fontColor: "#9f9f9f",
            beginAtZero: false,
            maxTicksLimit: 5,
          },
          gridLines: {
            drawBorder: false,
            zeroLineColor: "transparent",
            color: "rgba(255,255,255,0.05)",
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            drawBorder: false,
            color: "rgba(255,255,255,0.1)",
            zeroLineColor: "transparent",
            display: false,
          },
          ticks: {
            padding: 20,
            fontColor: "#9f9f9f",
          },
        },
      ],
    },
  },
};

// #########################################
// // // used inside src/views/Dashboard.js
// #########################################
const chartExample3 = {
  title: "Balance",
  description: "",
  statFooterText: "View Details",
  data: (canvas) => {
    let ctx = canvas.getContext("2d");

    let gradientStroke = ctx.createLinearGradient(500, 0, 100, 0);
    gradientStroke.addColorStop(0, "#2CA8FF");
    gradientStroke.addColorStop(1, chartColor);

    let gradientFill = ctx.createLinearGradient(0, 170, 0, 50);
    gradientFill.addColorStop(0, "rgba(128, 182, 244, 0)");
    gradientFill.addColorStop(1, hexToRGB("#2CA8FF", 0.4));
    return {
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
      ],
      datasets: [
        {
          label: "Active Countries",
          backgroundColor: gradientFill,
          borderColor: "#fbc658",
          pointHoverRadius: 0,
          pointRadius: 0,
          fill: false,
          borderWidth: 3,
          barPercentage: 1.6,
          data: [140, 180, 330, 4500, 5500, 3300, 4500, 3800, 4400, 7700, 6600, 8100],
        },
      ],
    };
  },
  options: {
    legend: {
      display: false,
    },
    tooltips: {
      enabled: false,
    },
    scales: {
      yAxes: [
        {
          ticks: {
            fontColor: "#9f9f9f",
            beginAtZero: false,
            maxTicksLimit: 5,
          },
          gridLines: {
            drawBorder: false,
            zeroLineColor: "transparent",
            color: "rgba(255,255,255,0.05)",
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            drawBorder: false,
            color: "rgba(255,255,255,0.1)",
            zeroLineColor: "transparent",
            display: false,
          },
          ticks: {
            padding: 20,
            fontColor: "#9f9f9f",
          },
        },
      ],
    },
  },
};

// #########################################
// // // used inside src/views/Dashboard.js
// #########################################
const chartExample4 = {
  title: "Activity",
  description: "Multiple Bars Chart",
  statFooterText: "View Details",
  data: {
    labels: [
      0,
      1,
      2,
      3,
      4,
      5,
      6,
      7,
      8,
      9,
      10,
      11,
      12,
      13,
      14,
      15,
      16,
      17,
      18,
      19,
      20,
    ],
    datasets: [
      {
        label: "Data",
        borderColor: "#fcc468",
        fill: true,
        backgroundColor: "#fcc468",
        hoverBorderColor: "#fcc468",
        borderWidth: 8,
        barPercentage: 0.4,
        data: [
          100,
          120,
          80,
          100,
          90,
          130,
          110,
          100,
          80,
          110,
          130,
          140,
          130,
          120,
          130,
          80,
          100,
          90,
          120,
          130,
        ],
      },
      {
        label: "Data",
        borderColor: "#4cbdd7",
        fill: true,
        backgroundColor: "#4cbdd7",
        hoverBorderColor: "#4cbdd7",
        borderWidth: 8,
        barPercentage: 0.4,
        data: [
          80,
          140,
          50,
          120,
          50,
          150,
          60,
          130,
          50,
          130,
          150,
          100,
          110,
          80,
          140,
          50,
          140,
          50,
          110,
          150,
        ],
      },
    ],
  },
  options: {
    tooltips: {
      tooltipFillColor: "rgba(0,0,0,0.5)",
      tooltipFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
      tooltipFontSize: 14,
      tooltipFontStyle: "normal",
      tooltipFontColor: "#fff",
      tooltipTitleFontFamily:
        "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
      tooltipTitleFontSize: 14,
      tooltipTitleFontStyle: "bold",
      tooltipTitleFontColor: "#fff",
      tooltipYPadding: 6,
      tooltipXPadding: 6,
      tooltipCaretSize: 8,
      tooltipCornerRadius: 6,
      tooltipXOffset: 10,
    },
    legend: {
      display: false,
    },
    scales: {
      yAxes: [
        {
          ticks: {
            fontColor: "#9f9f9f",
            fontStyle: "bold",
            beginAtZero: true,
            maxTicksLimit: 5,
            padding: 20,
          },
          gridLines: {
            zeroLineColor: "transparent",
            display: true,
            drawBorder: false,
            color: "#9f9f9f",
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            zeroLineColor: "white",
            display: false,

            drawBorder: false,
            color: "transparent",
          },
          ticks: {
            padding: 20,
            fontColor: "#9f9f9f",
            fontStyle: "bold",
          },
        },
      ],
    },
  },
};

// #########################################
// // // used inside src/views/Dashboard.js
// #########################################
const chartExample5 = {
  title: "24 Hours Performance",
  description: "Line Chart",
  statFooterText: "View Details",
  data: {
    labels: [1, 2],
    datasets: [
      {
        label: "Emails",
        pointRadius: 0,
        pointHoverRadius: 0,
        backgroundColor: ["#4acccd", "#f4f3ef"],
        borderWidth: 0,
        barPercentage: 1.6,
        data: [60, 40],
      },
    ],
  },
  options: {
    elements: {
      center: {
        text: "60%",
        color: "#66615c", // Default is #000000
        fontStyle: "Arial", // Default is Arial
        sidePadding: 60, // Defualt is 20 (as a percentage)
      },
    },
    cutoutPercentage: 90,
    legend: {
      display: false,
    },
    tooltips: {
      enabled: false,
    },
    scales: {
      yAxes: [
        {
          ticks: {
            display: false,
          },
          gridLines: {
            drawBorder: false,
            zeroLineColor: "transparent",
            color: "rgba(255,255,255,0.05)",
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            drawBorder: false,
            color: "rgba(255,255,255,0.1)",
            zeroLineColor: "transparent",
          },
          ticks: {
            display: false,
          },
        },
      ],
    },
  },
};

// #########################################
// // // used inside src/views/Dashboard.js
// #########################################
const chartExample6 = {
  title: "24 Hours Performance",
  description: "Line Chart",
  statFooterText: "View Details",
  data: {
    labels: [1, 2],
    datasets: [
      {
        label: "Emails",
        pointRadius: 0,
        pointHoverRadius: 0,
        backgroundColor: ["#fcc468", "#f4f3ef"],
        borderWidth: 0,
        barPercentage: 1.6,
        data: [34, 66],
      },
    ],
  },
  options: {
    elements: {
      center: {
        text: "34%",
        color: "#66615c", // Default is #000000
        fontStyle: "Arial", // Default is Arial
        sidePadding: 60, // Defualt is 20 (as a percentage)
      },
    },
    cutoutPercentage: 90,
    legend: {
      display: false,
    },
    tooltips: {
      enabled: false,
    },
    scales: {
      yAxes: [
        {
          ticks: {
            display: false,
          },
          gridLines: {
            drawBorder: false,
            zeroLineColor: "transparent",
            color: "rgba(255,255,255,0.05)",
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            drawBorder: false,
            color: "rgba(255,255,255,0.1)",
            zeroLineColor: "transparent",
          },
          ticks: {
            display: false,
          },
        },
      ],
    },
  },
};

// #########################################
// // // used inside src/views/Dashboard.js
// #########################################
const chartExample7 = {
  title: "24 Hours Performance",
  description: "Line Chart",
  data: {
    labels: [1, 2],
    datasets: [
      {
        label: "Emails",
        pointRadius: 0,
        pointHoverRadius: 0,
        backgroundColor: ["#f17e5d", "#f4f3ef"],
        borderWidth: 0,
        barPercentage: 1.6,
        data: [80, 20],
      },
    ],
  },
  options: {
    elements: {
      center: {
        text: "80%",
        color: "#66615c", // Default is #000000
        fontStyle: "Arial", // Default is Arial
        sidePadding: 60, // Defualt is 20 (as a percentage)
      },
    },
    cutoutPercentage: 90,
    legend: {
      display: false,
    },
    tooltips: {
      enabled: false,
    },
    scales: {
      yAxes: [
        {
          ticks: {
            display: false,
          },
          gridLines: {
            drawBorder: false,
            zeroLineColor: "transparent",
            color: "rgba(255,255,255,0.05)",
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            drawBorder: false,
            color: "rgba(255,255,255,0.1)",
            zeroLineColor: "transparent",
          },
          ticks: {
            display: false,
          },
        },
      ],
    },
  },
};

// #########################################
// // // used inside src/views/Dashboard.js
// #########################################
const chartExample8 = {
  title: "24 Hours Performance",
  description: "Line Chart",
  statFooterText: "View Details",
  data: {
    labels: [1, 2],
    datasets: [
      {
        label: "Emails",
        pointRadius: 0,
        pointHoverRadius: 0,
        backgroundColor: ["#66615b", "#f4f3ef"],
        borderWidth: 0,
        barPercentage: 1.6,
        data: [11, 89],
      },
    ],
  },
  options: {
    elements: {
      center: {
        text: "11%",
        color: "#66615c", // Default is #000000
        fontStyle: "Arial", // Default is Arial
        sidePadding: 60, // Defualt is 20 (as a percentage)
      },
    },
    cutoutPercentage: 90,
    legend: {
      display: false,
    },
    tooltips: {
      enabled: false,
    },
    scales: {
      yAxes: [
        {
          ticks: {
            display: false,
          },
          gridLines: {
            drawBorder: false,
            zeroLineColor: "transparent",
            color: "rgba(255,255,255,0.05)",
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            drawBorder: false,
            color: "rgba(255,255,255,0.1)",
            zeroLineColor: "transparent",
          },
          ticks: {
            display: false,
          },
        },
      ],
    },
  },
};

// #########################################
// // // used inside src/views/Charts.js
// #########################################

const chartExample9 = {
  title: "Balance",
  description: "",
  statFooterText: "View Details",
  data: {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
    datasets: [
      {
        label: "Active Users",
        borderColor: "#f17e5d",
        pointBackgroundColor: "#f17e5d",
        pointRadius: 3,
        pointHoverRadius: 3,
        lineTension: 0,
        fill: false,
        borderWidth: 3,
        barPercentage: 1.6,
        data: [140, 330, 4500, 6500, 7500, 4400, 5700],
      },
    ],
  },
  options: {
    legend: {
      display: false,
    },
    tooltips: {
      enabled: false,
    },
    scales: {
      yAxes: [
        {
          ticks: {
            fontColor: "#9f9f9f",
            beginAtZero: false,
            maxTicksLimit: 5,
          },
          gridLines: {
            drawBorder: false,
            borderDash: [8, 5],
            zeroLineColor: "transparent",
            color: "#9f9f9f",
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            drawBorder: false,
            borderDash: [8, 5],
            color: "#9f9f9f",
            zeroLineColor: "transparent",
          },
          ticks: {
            padding: 20,
            fontColor: "#9f9f9f",
          },
        },
      ],
    },
  },
};

// #########################################
// // // used inside src/views/Charts.js
// #########################################

const chartExample10 = {
  title: "Views",
  description: "Bar Chart",
  statFooterText: "View Details",
  data: {
    labels: [
      0,
      1,
      2,
      3,
      4,
      5,
      6,
      7,
      8,
      9,
      10,
      11,
      12,
      13,
      14,
      15,
      16,
      17,
      18,
      19,
      20,
    ],
    datasets: [
      {
        label: "Data",
        borderColor: "#fcc468",
        fill: true,
        backgroundColor: "#fcc468",
        hoverBorderColor: "#fcc468",
        borderWidth: 5,
        barPercentage: 0.4,
        data: [
          100,
          120,
          80,
          100,
          90,
          130,
          110,
          100,
          80,
          110,
          130,
          140,
          130,
          120,
          130,
          80,
          100,
          90,
          120,
          130,
        ],
      },
    ],
  },
  options: {
    tooltips: {
      tooltipFillColor: "rgba(0,0,0,0.5)",
      tooltipFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
      tooltipFontSize: 14,
      tooltipFontStyle: "normal",
      tooltipFontColor: "#fff",
      tooltipTitleFontFamily:
        "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
      tooltipTitleFontSize: 14,
      tooltipTitleFontStyle: "bold",
      tooltipTitleFontColor: "#fff",
      tooltipYPadding: 6,
      tooltipXPadding: 6,
      tooltipCaretSize: 8,
      tooltipCornerRadius: 6,
      tooltipXOffset: 10,
    },
    legend: {
      display: false,
    },
    scales: {
      yAxes: [
        {
          ticks: {
            fontColor: "#9f9f9f",
            fontStyle: "bold",
            beginAtZero: true,
            maxTicksLimit: 5,
            padding: 20,
          },
          gridLines: {
            zeroLineColor: "transparent",
            display: true,
            drawBorder: false,
            color: "#9f9f9f",
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            zeroLineColor: "white",
            display: false,
            drawBorder: false,
            color: "transparent",
          },
          ticks: {
            padding: 20,
            fontColor: "#9f9f9f",
            fontStyle: "bold",
          },
        },
      ],
    },
  },
};

// #########################################
// // // used inside src/views/Charts.js
// #########################################

const chartExample11 = {
  title: "Email Statistics",
  description: "Last Campaign Performance",
  statFooterText: "View Details",
  data: {
    labels: [1, 2, 3],
    datasets: [
      {
        label: "Emails",
        pointRadius: 0,
        pointHoverRadius: 0,
        backgroundColor: ["#e3e3e3", "#4acccd", "#fcc468"],
        borderWidth: 0,
        barPercentage: 1.6,
        data: [542, 480, 430],
      },
    ],
  },
  options: {
    legend: {
      display: false,
    },
    tooltips: {
      enabled: false,
    },
    scales: {
      yAxes: [
        {
          ticks: {
            display: false,
          },
          gridLines: {
            drawBorder: false,
            zeroLineColor: "transparent",
            color: "rgba(255,255,255,0.05)",
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            drawBorder: false,
            color: "rgba(255,255,255,0.1)",
            zeroLineColor: "transparent",
          },
          ticks: {
            display: false,
          },
        },
      ],
    },
  },
};

// #########################################
// // // used inside src/views/Charts.js
// #########################################

const chartExample12 = {
  title: "Users Behavior",
  description: "24 Hours Performance",
  statFooterText: "View Details",
  data: {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
    ],
    datasets: [
      {
        borderColor: "#6bd098",
        backgroundColor: "#6bd098",
        pointRadius: 0,
        pointHoverRadius: 0,
        borderWidth: 3,
        barPercentage: 1.6,
        data: [300, 310, 316, 322, 330, 326, 333, 345, 338, 354],
      },
      {
        borderColor: "#f17e5d",
        backgroundColor: "#f17e5d",
        pointRadius: 0,
        pointHoverRadius: 0,
        borderWidth: 3,
        barPercentage: 1.6,
        data: [320, 340, 365, 360, 370, 385, 390, 384, 408, 420],
      },
      {
        borderColor: "#fcc468",
        backgroundColor: "#fcc468",
        pointRadius: 0,
        pointHoverRadius: 0,
        borderWidth: 3,
        barPercentage: 1.6,
        data: [370, 394, 415, 409, 425, 445, 460, 450, 478, 484],
      },
    ],
  },
  options: {
    legend: {
      display: false,
    },
    tooltips: {
      enabled: false,
    },
    scales: {
      yAxes: [
        {
          ticks: {
            fontColor: "#9f9f9f",
            beginAtZero: false,
            maxTicksLimit: 5,
          },
          gridLines: {
            drawBorder: false,
            zeroLineColor: "transparent",
            color: "rgba(255,255,255,0.05)",
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            drawBorder: false,
            color: "rgba(255,255,255,0.1)",
            zeroLineColor: "transparent",
            display: false,
          },
          ticks: {
            padding: 20,
            fontColor: "#9f9f9f",
          },
        },
      ],
    },
  },
};

const financialInstruments = {
    options: {
        Stocks:  [
            { symbol: "NIO", name: "NIO limited" },
            { symbol: "INTC", name: "Intel Corporation" },
            { symbol: "VIAC", name: "ViacomCBS Inc." },
            { symbol: "AAPL", name: "Apple Inc." },
            { symbol: "AMC", name: "AMC Entertainment Holdings, Inc." },
            { symbol: "CCL", name: "Carnival Corporation & plc" },
            { symbol: "PLTR", name: "Palantir Technologies Inc." },
            { symbol: "RLX", name: "RLX Technology Inc." },
            { symbol: "GE", name: "General Electric Company" },
            { symbol: "F", name: "Ford Motor Company" },
            { symbol: "ITUB", name: "Itaú Unibanco Holding S.A." },
            { symbol: "AAL", name: "American Airlines Group Inc." },
            { symbol: "NCLH", name: "Norwegian Cruise Line Holdings Ltd." },
            { symbol: "AMD", name: "Advanced Micro Devices, Inc." },
            { symbol: "BAC", name: "Bank of America Corporation" },
            { symbol: "TME", name: "Tencent Music Entertainment Group	" },
            { symbol: "NOK", name: "Nokia Corporation" },
            { symbol: "TSLA", name: "Tesla, Inc." },
            { symbol: "BNGO", name: "Bionano Genomics, Inc." },
            { symbol: "IQ", name: "iQIYI, Inc." },
            { symbol: "XPEV", name: "XPeng Inc.y" },
            { symbol: "T", name: "AT&T Inc." },
            { symbol: "PBR", name: "Petróleo Brasileiro S.A. - Petrobras" },
            { symbol: "BBD", name: "Banco Bradesco S.A." },
            { symbol: "TLRY", name: "Tilray, Inc." },

          ],

        Equity: [
            //  { symbol: "ESH", group: "E-Mini S&P 500", month: "March", year: "24"},
              { symbol: "ESM", group: "E-Mini S&P 500", month: "June", year: "24"},
            //  { symbol: "ESU", group: "E-Mini S&P 500", month: "Sept", year: "23"},
            //  { symbol: "ESZ", group: "E-Mini S&P 500", month: "Dec", year: "23"},

              // { symbol: "NQH", group: "E-Mini Nasdaq 100", month: "March", year: "24" },
               { symbol: "NQM", group: "E-Mini Nasdaq 100", month: "June", year: "24" },
              // { symbol: "NQU", group: "E-Mini Nasdaq 100", month: "Sept", year: "23" },
               //{ symbol: "NQZ", group: "E-Mini Nasdaq 100", month: "Dec", year: "23" },

              // { symbol: "YMH", group: "E-Mini Dow Jones Industrial Average Index", month: "March", year: "24" },
               { symbol: "YMM", group: "E-Mini Dow Jones Industrial Average Index", month: "June", year: "24" },
              // { symbol: "YMU", group: "E-Mini Dow Jones Industrial Average Index", month: "Sept", year: "23" },
              // { symbol: "YMZ", group: "E-Mini Dow Jones Industrial Average Index", month: "Dec", year: "23" },
        ],
        Metals: [
              { symbol: "QOG", group: "E-Mini Gold", month: "Feb", year: "24"},
              //{ symbol: "QGCJ", group: "Gold", month: "April", year: "23"},
            //  { symbol: "QGCM", group: "Gold", month: "June", year: "23"},
            //  { symbol: "QGCQ", group: "Gold", month: "Aug", year: "23"},
            //  { symbol: "QGCV", group: "Gold", month: "Oct", year: "23"},
            //  { symbol: "QGCZ", group: "Gold", month: "Dec", year: "23"},
        ],
        Energies: [
              //{ symbol: "CLF", group: "Crude Oil", month: "Jan", year: "23"},
              //{ symbol: "QCLG", group: "Crude Oil", month: "Feb", year: "24"},
            //  { symbol: "QCLH", group: "Crude Oil", month: "March", year: "24"},
              //{ symbol: "QCLJ", group: "Crude Oil", month: "April", year: "23"},
              //{ symbol: "QCLK", group: "Crude Oil", month: "May", year: "23"},
              { symbol: "QCLM", group: "Crude Oil", month: "June", year: "24"},
              //{ symbol: "QCLN", group: "Crude Oil", month: "July", year: "23"},
              //{ symbol: "QCLQ", group: "Crude Oil", month: "Aug", year: "23"},
              //{ symbol: "QCLU", group: "Crude Oil", month: "Sep", year: "23"},
              //{ symbol: "QCLV", group: "Crude Oil", month: "Oct", year: "23"},
              //{ symbol: "QCLX", group: "Crude Oil", month: "Nov", year: "23"},
              //{ symbol: "QCLZ", group: "Crude Oil", month: "Dec", year: "23"},

               { symbol: "NGTM", group: "Natural Gas", month: "June", year: "24"},
              // { symbol: "NGTN", group: "Natural Gas", month: "July", year: "23"},
              // { symbol: "NGTQ", group: "Natural Gas", month: "Aug", year: "23"},
              // { symbol: "NGTU", group: "Natural Gas", month: "Sep", year: "23"},
              // { symbol: "NGTV", group: "Natural Gas", month: "Oct", year: "23"},
              // { symbol: "NGTX", group: "Natural Gas", month: "Nov", year: "23"},
              // { symbol: "NGTZ", group: "Natural Gas", month: "Dec", year: "23"},
              //{ symbol: "NGTF", group: "Natural Gas", month: "Jan", year: "24"},
              //{ symbol: "NGTG", group: "Natural Gas", month: "Feb", year: "24"},
            //  { symbol: "NGTH", group: "Natural Gas", month: "March", year: "24"},
              //{ symbol: "NGTJ", group: "Natural Gas", month: "April", year: "24"},
            //  { symbol: "NGTK", group: "Natural Gas", month: "May", year: "24"},


            //  { symbol: "QRBG", group: "RBOB Gasoline", month: "Feb", year: "24"},
            //  { symbol: "QRBH", group: "RBOB Gasoline", month: "March", year: "24"},
            //  { symbol: "QRBJ", group: "RBOB Gasoline", month: "April", year: "24"},
            //  { symbol: "QRBK", group: "RBOB Gasoline", month: "May", year: "24"},
              { symbol: "QRBM", group: "RBOB Gasoline", month: "June", year: "24"},
            //  { symbol: "QRBN", group: "RBOB Gasoline", month: "July", year: "24"},
            //  { symbol: "QRBQ", group: "RBOB Gasoline", month: "Aug", year: "24"},
            //  { symbol: "QRBU", group: "RBOB Gasoline", month: "Sep", year: "24"},
            //  { symbol: "QRBV", group: "RBOB Gasoline", month: "Oct", year: "24"},
            //  { symbol: "QRBX", group: "RBOB Gasoline", month: "Nov", year: "24"},
            //  { symbol: "QRBZ", group: "RBOB Gasoline", month: "Dec", year: "24"},
            //  { symbol: "QRBF", group: "RBOB Gasoline", month: "Jan", year: "25"},

        ],
        Grains: [


          //{ symbol: "CH", group: "Corn", month: "March", year: "24"},
          //{ symbol: "CK", group: "Corn", month: "May", year: "24"},
          { symbol: "CN", group: "Corn", month: "July", year: "24"},
        //  { symbol: "CU", group: "Corn", month: "Sep", year: "24"},
        //  { symbol: "CZ", group: "Corn", month: "Dec", year: "24"},
        //  { symbol: "CH", group: "Corn", month: "March", year: "25"},
        //  { symbol: "CK", group: "Corn", month: "May", year: "25"},
        //  { symbol: "CN", group: "Corn", month: "July", year: "25"},
        //  { symbol: "CU", group: "Corn", month: "Sep", year: "25"},
        //  { symbol: "CZ", group: "Corn", month: "Dec", year: "25"},
        //  { symbol: "CN", group: "Corn", month: "July", year: "26"},
        //  { symbol: "CZ", group: "Corn", month: "Dec", year: "26"},



          //{ symbol: "WU", group: "Wheat", month: "Sep", year: "23"},
          //{ symbol: "WZ", group: "Wheat", month: "Dec", year: "23"},
        //  { symbol: "WH", group: "Wheat", month: "March", year: "24"},
        //  { symbol: "WK", group: "Wheat", month: "May", year: "24"},
          { symbol: "WN", group: "Wheat", month: "July", year: "24"},
        //  { symbol: "WU", group: "Wheat", month: "Sep", year: "24"},
          //{ symbol: "WZ", group: "Wheat", month: "Dec", year: "24"},
        //  { symbol: "WH", group: "Wheat", month: "March", year: "25"},
        //  { symbol: "WK", group: "Wheat", month: "May", year: "25"},
        //  { symbol: "WN", group: "Wheat", month: "July", year: "25"},

        ],
        Currencies: [
              //{ symbol: "6EH", group: "Euro FX Futures", month: "March", year: "24"},
               { symbol: "6EM", group: "Euro FX Futures", month: "June", year: "24"},
              // { symbol: "6EU", group: "Euro FX Futures", month: "Sept", year: "23"},
              // { symbol: "6EZ", group: "Euro FX Futures", month: "Dec", year: "23"},

               //{ symbol: "6JH", group: "Japanese Yen Futures", month: "March", year: "24" },
                { symbol: "6JM", group: "Japanese Yen Futures", month: "June", year: "24" },
               // { symbol: "6JU", group: "Japanese Yen Futures", month: "Sept", year: "23" },
               // { symbol: "6JZ", group: "Japanese Yen Futures", month: "Dec", year: "23" },

              // { symbol: "6BH", group: "British Pound Futures", month: "March", year: "24" },
                { symbol: "6BM", group: "British Pound Futures", month: "June", year: "24" },
               // { symbol: "6BU", group: "British Pound Futures", month: "Sept", year: "23" },
               // { symbol: "6BZ", group: "British Pound Futures", month: "Dec", year: "23" },

              // { symbol: "6AH", group: "Australian Dollar Futures", month: "March", year: "24" },
                { symbol: "6AM", group: "Australian Dollar Futures", month: "June", year: "24" },
               // { symbol: "6AU", group: "Australian Dollar Futures", month: "Sept", year: "23" },
               // { symbol: "6AZ", group: "Australian Dollar Futures", month: "Dec", year: "23" },

               //{ symbol: "6CH", group: "Canadian Dollar Futures", month: "March", year: "24" },
                { symbol: "6CM", group: "Canadian Dollar Futures", month: "June", year: "24" },
               // { symbol: "6CU", group: "Canadian Dollar Futures", month: "Sept", year: "23" },
               // { symbol: "6CZ", group: "Canadian Dollar Futures", month: "Dec", year: "23" },

        ],
        Crypto: [
               { symbol: "MBTJ", group: "Micro Bitcoin", month: "April", year: "24"},
              // { symbol: "MBTK", group: "Micro Bitcoin", month: "May", year: "23"},
              // { symbol: "MBTM", group: "Micro Bitcoin", month: "June", year: "23"},
              // { symbol: "MBTN", group: "Micro Bitcoin", month: "July", year: "23"},
              // { symbol: "MBTQ", group: "Micro Bitcoin", month: "Aug", year: "23"},
              // { symbol: "MBTU", group: "Micro Bitcoin", month: "Sept", year: "23"},
              //{ symbol: "MBTZ", group: "Micro Bitcoin", month: "Dec", year: "23"},

               { symbol: "METJ", group: "Micro Ether", month: "April", year: "24"},
              // { symbol: "METK", group: "Micro Ether", month: "May", year: "23"},
              // { symbol: "METM", group: "Micro Ether", month: "June", year: "23"},
              // { symbol: "METN", group: "Micro Ether", month: "July", year: "23"},
              // { symbol: "METQ", group: "Micro Ether", month: "Aug", year: "23"},
              // { symbol: "METU", group: "Micro Ether", month: "Sept", year: "23"},
              //{ symbol: "METZ", group: "Micro Ether", month: "Dec", year: "23"},

            // { symbol: "ETH-USD", name: "Ethereum USD" },
            // { symbol: "USDT-USD", name: "Tether USD" },
            // { symbol: "BNB-USD", name: "BinanceCoin USD" },
            // { symbol: "ADA-USD", name: "Cardano USD" },
            // { symbol: "DOT1-USD", name: "Polkadot USD" },
            // { symbol: "XRP-USD", name: "XRP USD" },
            // { symbol: "LTC-USD", name: "Litecoin USD" },
            // { symbol: "THETA-USD", name: "THETA USD" },
            // { symbol: "LINK-USD", name: "Chainlink USD" },
            // { symbol: "USDC-USD", name: "USDCoin USD" },
            // { symbol: "BCH-USD", name: "BitcoinCash USD" },
            // { symbol: "XLM-USD", name: "Stellar USD" },
            // { symbol: "LUNA1-USD", name: "Terra USD" },
            // { symbol: "DOGE-USD", name: "Dogecoin USD" },
            // { symbol: "VET-USD", name: "VeChain USD" },
            // { symbol: "MIOTA-USD", name: "IOTA USD" },
            // { symbol: "TRX-USD", name: "TRON USD" },
            // { symbol: "BSV-USD", name: "BitcoinSV USD" },
            // { symbol: "XMR-USD", name: "Monero USD" },
            // { symbol: "EOS-USD", name: "EOS USD" },
            // { symbol: "SOL1-USD", name: "Solana USD" },
            // { symbol: "ATOM1-USD", name: "Cosmos USD" },
            // { symbol: "KSM-USD", name: "Kusama USD" },
        ],
        MutualFunds: [
          { symbol: "DHMCX", name: "Diamond Hill Small Mid Cap Fund Class C" },
          { symbol: "NNGAX", name: "Nuveen Large-Cap Value Fund Class A" },
          { symbol: "NNGRX", name: "Nuveen Large-Cap Value Fund Class I" },
          { symbol: "NMMTX", name: "Nuveen Large-Cap Value Fund Class R3" },
          { symbol: "WHOSX", name: "Wasatch-Hoisington U.S. Treasury Fund" },
          { symbol: "DHROX", name: "Diamond Hill Research Opportunities Fund Class A" },
          { symbol: "DROYX", name: "Diamond Hill Research Opportunities Fund Class Y" },
          { symbol: "DROCX", name: "Diamond Hill Research Opportunities Fund Class C" },
          { symbol: "DHTCX", name: "Diamond Hill All Cap Select Fund Class C" },
          { symbol: "NLIGX", name: "Nuveen Large Cap Growth Fund Class I" },
          { symbol: "NLCGX", name: "Nuveen Large Cap Growth Fund Class C" },
          { symbol: "MNREX", name: "Manning & Napier Real Estate Series Class S" },
          { symbol: "VMRGX", name: "Vanguard Morgan Growth Fund" },
          { symbol: "GASFX", name: "Hennessy Gas Utility Fund Investor Class" },
          { symbol: "VUVLX", name: "Vanguard U.S. Value Fund Investor Shares" },
          { symbol: "BLVAX", name: "BMO Low Volatility Equity Fund Class A" },
          { symbol: "FDCGX", name: "Fidelity Advisor Consumer Staples Fund Class C" },
          { symbol: "FDIGX", name: "Fidelity Advisor Consumer Staples Fund Class I" },
          { symbol: "FDFAX", name: "Fidelity Select Consumer Staples Portfolio" },
          { symbol: "CLDIX", name: "Calvert Core Bond Fund Class I" },
          { symbol: "GIUSX", name: "Guggenheim Investment Grade Bond Fund Institutional Class" },
          { symbol: "SIUSX", name: "Guggenheim Investment Grade Bond Fund Class A" },
          { symbol: "SIUPX", name: "Guggenheim Investment Grade Bond Fund Class P" },
          { symbol: "JHNBX", name: "John Hancock Bond Fund Class A" },
          { symbol: "JBFRX", name: "John Hancock Bond Fund Class R4" },
        ],
        Forex: [
          { symbol: "BTCUSD=X", name: "BTCUSD=X" },
          { symbol: "ETHUSD=X", name: "ETHUSD=X" },
          { symbol: "EURUSD=X", name: "EUR/USD" },
          { symbol: "JPY=X", name: "GBP/USD" },
          { symbol: "AUDUSD=X", name: "AUD/USD" },
          { symbol: "NZDUSD=X", name: "NZD/USD" },
          { symbol: "XRP-USD", name: "XRP USD" },
          { symbol: "EURJPY=X", name: "EUR/JPY" },
          { symbol: "GBPJPY=X", name: "GBP/JPY" },
          { symbol: "EURGBP=X", name: "EUR/GBP" },
          { symbol: "EURCAD=X", name: "EUR/CAD" },
          { symbol: "EURSEK=X", name: "EUR/SEK" },
          { symbol: "EURCHF=X", name: "EUR/CHF" },
          { symbol: "EURHUF=X", name: "EUR/HUF" },
          { symbol: "EURJPY=X", name: "EUR/JPY" },
          { symbol: "CNY=X", name: "USD/CNY" },
          { symbol: "HKD=X", name: "USD/HKD" },
          { symbol: "SGD=X", name: "USD/SGD" },
          { symbol: "INR=X", name: "USD/INR" },
          { symbol: "MXN=X", name: "USD/MXN" },
          { symbol: "PHP=X", name: "USD/PHP" },
          { symbol: "IDR=X", name: "USD/IDR" },
          { symbol: "THB=X", name: "USD/THB" },
          { symbol: "MYR=X", name: "USD/MYR" },
          { symbol: "ZAR=X", name: "USD/ZAR" },
          { symbol: "RUB=X", name: "USD/RUB" },
        ],
      }
}

module.exports = {
  // used for Charts and Dashboard views
  chartExample1,
  // used for Dashboard view
  chartExample2,
  // used for Dashboard view
  chartExample3,
  // used for Charts and Dashboard views
  chartExample4,
  // used for Dashboard view
  chartExample5,
  // used for Dashboard view
  chartExample6,
  // used for Dashboard view
  chartExample7,
  // used for Dashboard view
  chartExample8,
  // used for Charts view
  chartExample9,
  // used for Charts view
  chartExample10,
  // used for Charts view
  chartExample11,
  // used for Charts view
  chartExample12,

  financialInstruments,
};
