/*!

=========================================================
* Paper Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React,  { useState, useEffect }  from "react";
import  UserProvider  from "providers/UserProvider";
import bcrypt from 'bcryptjs';
import { NavLink } from "react-router-dom";

// reactstrap components
import {
  Alert,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";


const ResetPassword = () => {

const [error, setError] = useState("")
const [isEmptyFields, setIsEmptyFields] = useState(true)
const [loading, setLoading] = useState(false)
const [password, setPassword] = useState("");
const [passwordConfirm, setPasswordConfirm] = useState("");
const [message, setMessage] = useState("");

// SALT should be created ONE TIME upon sign up
const salt = "$2a$10$lrRPFX9uc1K8xinbXrxQju";//bcrypt.genSaltSync(10);


useEffect(() => {
   //document.body.classList.toggle("register-page");
   //setRequiredFields(requiredFields());
   if(error.length>0)
     return;

   requiredFieldsEmpty()

   if(password !== passwordConfirm )
       setError("Passwords must match")
   else
      setError("")

  debugger

},[password, passwordConfirm, loading, isEmptyFields, error])

const requiredFieldsEmpty=()=>{
  if(password.length > 0 && passwordConfirm.length > 0){
    setIsEmptyFields(false)
    return false
  }
  else {
    setIsEmptyFields(true)
    return true
  }
}

const validatePassword = (event) => {
  event.preventDefault();
  const { name, value } = event.currentTarget;
  const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{5,}$/;
  if (re.test(String(value))  && value.length > 0 ){
    debugger
    setError("")
    if (name === "password"){
      const hashedPassword = bcrypt.hashSync(value, salt)
      //console.log("Salt is " + salt);
       setPassword(hashedPassword);
      //  setPassword(value);
    }
    if (name === "passwordConfirm"){
       const hashedPassword = bcrypt.hashSync(value, salt)
      // console.log("Salt is " + salt);
      setPasswordConfirm(hashedPassword);
      //  setPasswordConfirm(value);
    }
    return true
  }
  else {
    debugger
    setError("Invalid Password: Minimum 5 characters, at least one uppercase letter, one lowercase letter and one number:")
    return false
  }
}

   async function handleResetPassword(event){
    //event.preventDefault()
    debugger;
    if(error.length>0)
      return;
    if(requiredFieldsEmpty())
      return setError("Please fill in all required fields");

    debugger
    setError("")
    setMessage("")
    setLoading(true)

    var code = new URL(window.location.href).searchParams.get("oobCode");
    await new UserProvider().confirmResetPasswordAction( password, code)
    .then(data =>
    {
      //history.push("/admin/my-profile")
      setMessage('New Password was set. You can login with the new password now.')
      setLoading(false)
    })
    .catch( err =>
    {
        return setError(err.message);
    })
       setLoading(false)
  }
    return (
      <div className="register-page">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" lg="4" md="6">
              <Card className="card-signup text-center">
                <CardHeader>
                  <CardTitle tag="h4">Reset Password</CardTitle>
                </CardHeader>
                <CardBody>
                  <Form action="" className="form" method="">
                    {error && <Alert color = "danger">{error}</Alert>}
                    {message && <Alert color = "success">{message}</Alert>}
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="nc-icon nc-circle-10" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input name="password" placeholder="Password" type="password"
                      onChange={e=>validatePassword(e)} />
                    </InputGroup>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="nc-icon nc-circle-10" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input name="passwordConfirm" placeholder="PasswordConfirm" type="password"
                      onChange={e=>validatePassword(e)} />
                    </InputGroup>
                  </Form>
                </CardBody>
                <CardFooter>
                  <Button
                    disabled={loading || error.length>0 || isEmptyFields}
                    className="btn-round"
                    color="info"
                    onClick={event => handleResetPassword(event)}
                  >
                    Change Password
                  </Button>
                  <FormGroup>
                    <NavLink to="/auth/login" className="nav-link">
                      Login
                    </NavLink>
                  </FormGroup>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );

}

export default ResetPassword;
