/*!

=========================================================
* Paper Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useContext } from "react";
import classnames from "classnames";
import { UserContext } from "providers/UserProvider.js";
import  UserProvider  from "providers/UserProvider";
import { useHistory } from "react-router-dom";

import logoFull from "assets/img/VerifyTraderLogoHalf.png";

import {
  Alert,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  Container,
} from "reactstrap";

import { NavLink, Link, } from "react-router-dom";

export const SignOut = () => {

    const [error, setError] = useState("")
    const history = useHistory();
    async function logOut(event){
      try {
             setError("")
             event.preventDefault()
             await new UserProvider().signoutAction()
             history.push("/auth/login")
          } catch(error){
            debugger
          //  return alert(error)
             setError("Failed to Log Out");
          }
        }


  return (
    <DropdownMenu
      persist
      aria-labelledby="navbarDropdownMenuLink"
      right>
      {error && <Alert color = "danger">{error}</Alert>}
      <DropdownItem onClick = {event => logOut(event)}> Sign Out </DropdownItem>
    </DropdownMenu>
  );
}

function NavbarActions() {
  const userRef = useContext(UserContext);
  if (userRef.user != null) {
     return (
       <>
           <NavItem>
             <NavLink to="/admin/my-profile"
               className="nav-link"
             >
               <i className="nc-icon nc-single-02" />
               <p>
                 <span className="d-lg-none d-md-block">My Profile</span>
               </p>
             </NavLink>
           </NavItem>
             <UncontrolledDropdown className="btn-rotate" nav>
               <DropdownToggle
                 aria-haspopup={true}
                 caret
                 color="default"
                 data-toggle="dropdown"
                 id="navbarDropdownMenuLink"
                 nav>
                       <i className="nc-icon nc-settings-gear-65" />
                       <p>
                         <span className="d-lg-none d-md-block">Account</span>
                       </p>

                 </DropdownToggle>
                    <SignOut>
                     <DropdownMenu
                       persist
                       aria-labelledby="navbarDropdownMenuLink"
                       right>
                       <DropdownItem/>
                     </DropdownMenu>
                    </SignOut>
            </UncontrolledDropdown>
        </>
      )
     }
   return (
   <>
   <Link
     size="m"
     to={{
             pathname:"/auth/login"
              }}
      >
      <div  className="btn-round btn-primary btn">
        Login
      </div>
    </Link>
   </>
 )

}

class AdminNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseOpen: false,
      color: "navbar-transparent",
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateColor);
  }
  componentDidUpdate(e) {
    if (
      window.outerWidth < 993 &&
      e.history.location.pathname !== e.location.pathname &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
    }
  }

  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  updateColor = () => {
    if (window.innerWidth < 993 && this.state.collapseOpen) {
      this.setState({
        color: "bg-white",
      });
    } else {
      this.setState({
        color: "navbar-transparent",
      });
    }
  };
  // this function opens and closes the sidebar on small devices
  toggleSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
  };
  // this function opens and closes the collapse on small devices
  // it also adds navbar-transparent class to the navbar when closed
  // ad bg-white when opened
  toggleCollapse = () => {
    let newState = {
      collapseOpen: !this.state.collapseOpen,
    };
    if (!this.state.collapseOpen) {
      newState["color"] = "bg-white";
    } else {
      newState["color"] = "navbar-transparent";
    }
    this.setState(newState);
  };
/*
  async logOut(event){
    try {
           debugger
          /// setError("")
           event.preventDefault()
           await new UserProvider().signoutAction()
           debugger
           this.props.history.push("/auth/login")
        } catch(error){
          debugger
        //  return alert(error)
           //setError("Failed to Log Out");
        }
  }
*/

  render() {

    return (
      <>

        <Navbar
          className={classnames("navbar-absolute fixed-top", this.state.color)}
          expand="lg"
        >
          <Container fluid>
            <div className="navbar-wrapper">
              <div className="navbar-minimize">
      {/* //CHANGE THIS BACK to #bringbacksidebar
                <Button
                  className="btn-icon btn-round"
                  color="default"
                  id="minimizeSidebar"
                  onClick={this.props.handleMiniClick}
                >
                  <i className="nc-icon nc-minimal-right text-center visible-on-sidebar-mini" />
                  <i className="nc-icon nc-minimal-left text-center visible-on-sidebar-regular" />
                </Button>
  //CHANGE THIS BACK to #bringbacksidebar      */}
              </div>
              <div
                className={classnames("navbar-toggle", {
                  toggled: this.state.sidebarOpen,
                })}
              >
    {/* //CHANGE THIS BACK to #bringbacksidebar
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={this.toggleSidebar}
                >
                  <span className="navbar-toggler-bar bar1" />
                  <span className="navbar-toggler-bar bar2" />
                  <span className="navbar-toggler-bar bar3" />
                </button>
//CHANGE THIS BACK to #bringbacksidebar      */}
              </div>
              <NavbarBrand href="/admin/front-page">
                <span className="d-none d-md-block">
                  <div className="logo-container">
                    <img src={logoFull} alt="v-logo" />
                  </div>
                </span>
                <span className="d-block d-md-none">Trader Bureau</span>
              </NavbarBrand>
            </div>
            <button
              aria-controls="navigation-index"
              aria-expanded={this.state.collapseOpen}
              aria-label="Toggle navigation"
              className="navbar-toggler"
              // data-target="#navigation"
              data-toggle="collapse"
              type="button"
              onClick={this.toggleCollapse}
            >
              <span className="navbar-toggler-bar navbar-kebab" />
              <span className="navbar-toggler-bar navbar-kebab" />
              <span className="navbar-toggler-bar navbar-kebab" />
            </button>
            <Collapse
              className="justify-content-end"
              navbar
              isOpen={this.state.collapseOpen}
            >
            {/*
              <Form>
                <InputGroup className="no-border">
                  <Input defaultValue="" placeholder="Search..." type="text" />
                  <InputGroupAddon addonType="append">
                    <InputGroupText>
                      <i className="nc-icon nc-zoom-split" />
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </Form>
            */}
              <Nav navbar>
    {/*
                <NavItem>
                  <NavLink
                    className="btn-magnify"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    <i className="nc-icon nc-layout-11" />
                    <p>
                      <span className="d-lg-none d-md-block">Stats</span>
                    </p>
                  </NavLink>
                </NavItem>
                <UncontrolledDropdown className="btn-rotate" nav>
                  <DropdownToggle
                    aria-haspopup={true}
                    caret
                    color="default"
                    data-toggle="dropdown"
                    id="navbarDropdownMenuLink"
                    nav
                  >
                    <i className="nc-icon nc-bell-55" />
                    <p>
                      <span className="d-lg-none d-md-block">Some Actions</span>
                    </p>
                  </DropdownToggle>
                  <DropdownMenu
                    persist
                    aria-labelledby="navbarDropdownMenuLink"
                    right
                  >
                    <DropdownItem
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      Action
                    </DropdownItem>
                    <DropdownItem
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      Another action
                    </DropdownItem>
                    <DropdownItem
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      Something else here
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
                  */}

                  <NavbarActions  />
              </Nav>

            </Collapse>
          </Container>
        </Navbar>
      </>
    );
  }
}

//export default AdminNavbar;
//const mapStateToProps = state => ({...state});
//const mapDispatchToProps = dispatch => ({
//  signoutAction:() =>
  //    dispatch(
  //      signoutAction()
  //    )
//})
export default AdminNavbar;
//withRouter(connect(
//  mapStateToProps,
  //mapDispatchToProps
//)(AdminNavbar));
