/*!

=========================================================
* Paper Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { UserContext } from "providers/UserProvider.js";
import   { ShareTradeRecord, GetMyTrades, CreateTrade, DeleteTrade, MarkToDeleteTrade }   from "providers/TradesProvider.js";
import firebase from "firebaseConfig";

// react plugin used to create DropdownMenu for selecting items
import Select, { components } from "react-select";

import moment from 'moment';

// react plugin used to create datetimepicker
import ReactDatetime from "react-datetime";

import "react-datetime/css/react-datetime.css";

import Collapsible from 'react-collapsible';

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  UncontrolledTooltip,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
} from "reactstrap";

// core components
import ReactTable from "components/ReactTable/ReactTable.js";
import copy from 'copy-to-clipboard';
// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";


// core components
 import {
   financialInstruments,
 } from "variables/charts.js";
 // const initialState = {
 //   sortBy: [
 //     {
 //       id: "timestamp_created",
 //       desc: true
 //     }
 //   ]
 // };

const sortOptions = [{ id: 'timestamp_created', desc: true }];
const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};
const groupBadgeStyles = {
  backgroundColor: '#EBECF0',
  borderRadius: '2em',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: 12,
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center',
};

const formatGroupLabel = data => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
);

const HideGroupHeading = (props) => {
  return (
    <div
      className="collapse-group-heading"
      onClick={() => {
        document
          .querySelector(`#${props.id}`)
          .parentElement.parentElement.classList.toggle("collapsed-group");
      }}
    >
      <components.GroupHeading {...props} />
    </div>
  );
};

const HideGroupMenuList = (props) => {
  let new_props = {
    ...props,
    children: Array.isArray(props.children)
      ? props.children.map((c, idx) =>
          idx === 0
            ? c
            : { ...c, props: { ...c.props, className: "collapsed-group" } }
        )
      : props.children
  };

  return <components.MenuList {...new_props} />;
};

var databaseRef = firebase.firestore();
var docData = null;
var isSubmitBlocked = false;

class MyProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sortOptions: [{ id: 'timestamp_created', desc: true }],
      initialState: {
        sortBy: [
          {
            id: "timestamp_created",
            desc: true
          }
        ]
      },
      showTrackRecordTable:false,
      copied: false,
      shortLinkValue: "",
      required: "",
      requiredEntryState: "",
      requiredStopState: "",
      requiredTargetState:"",
      requiredVolumeState:"",
      requiredSymbolState:"",
      requiredCloseState:"",
      requiredValidDateState:"",
      requiredEntryNumberState:"",
      requiredStopNumberState:"",
      requiredTargetNumberState:"",
      requiredVolumeNumberState:"",
      horizontalTabs: "trades",
      trader_firstName: "",
      trader_lastName: "",
      trader_photo: require("assets/img/default-avatar.png"),
      trade: {
        instrument: "",
        entryprice: "",
        trade: "",
        stoploss: "",
        tradeprofit: "",
        target: "",
        tradevolume:"",
        validFrom: "",
        validUntil: "",
        trade_status: [],
        //trade_status: "recorded",
        //trade_status_reason: "",
        timestamp_created: "",
        trade_id:"",

      },
      stats: {
        initial_amount: 10000,
        balance: 10000,
        total_trades: 0,
        verified_positive_trades: 0,
        verified_negative_trades: 0,
      },
      data: [],
      groupedOptions:  [  ],
    };
     this.change = this.change.bind(this);
     this.showControlledResponse = this.showControlledResponse.bind(this);

  }

  static contextType = UserContext

  componentDidMount(){
    const userRef = this.context
    const { history } = this.props;

    //debugger;
    if(userRef.user == null){
      console.log("User is logged out")
      if(history) history.push("/auth/login")
      return
    }
    databaseRef.collection('users').doc(userRef.user.uid).get().then(
          (userRecord) => {
              if (userRecord.exists) {
                  docData = userRecord.data();
                  console.log("Document data:", docData);
                  this.setState({
                                  trader_firstName: docData.name.first_name,
                                  trader_lastName:  docData.name.last_name,
                                  stats:{
                                    initial_amount: docData.stats.initial_amount,
                                    balance: docData.stats.balance,
                                    total_trades: docData.stats.total_trades,
                                    verified_positive_trades:  docData.stats.verified_positive_trades,
                                    verified_negative_trades:  docData.stats.verified_negative_trades,
                                  }
                                });

              } else {
                  // docData will be undefined in this case
                  console.log("No such document!");
              }
          }
        ).catch((error) => {
            console.log("Error getting document:", error);
        });

    this.setState({
      groupedOptions:  [
/*
        {
          label: "Stocks",
          options:  financialInstruments.options.Stocks.map((prop, key)=>{
             return{
               id: key,
               symbol: prop.symbol,
               name: prop.name,
             }
          })
        },
          */
          {
            label: "Equity",
            options: financialInstruments.options.Equity.map((prop, key)=>{

                          return{
                            id:key,
                            value: prop.symbol,
                            group: prop.group,
                            month: prop.month,
                            year:prop.year,

                           }

              })
            },
            {
              label: "Metals",
              options: financialInstruments.options.Metals.map((prop, key)=>{

                            return{
                              id:key,
                              value: prop.symbol,
                              group: prop.group,
                              month: prop.month,
                              year:prop.year,

                             }

                })
              },
              {
                label: "Energies",
                options: financialInstruments.options.Energies.map((prop, key)=>{

                              return{
                                id:key,
                                value: prop.symbol,
                                group: prop.group,
                                month: prop.month,
                                year:prop.year,

                               }

                  })
                },
                {
                  label: "Grains",
                  options: financialInstruments.options.Grains.map((prop, key)=>{

                                return{
                                  id:key,
                                  value: prop.symbol,
                                  group: prop.group,
                                  month: prop.month,
                                  year:prop.year,

                                 }

                    })
                  },
                {
                  label: "Currencies",
                  options: financialInstruments.options.Currencies.map((prop, key)=>{

                                return{
                                  id:key,
                                  value: prop.symbol,
                                  group: prop.group,
                                  month: prop.month,
                                  year:prop.year,

                                 }

                    })
                  },
              {
                label: "Crypto",
                options:  financialInstruments.options.Crypto.map((prop, key)=>{

                           return{
                             id: key,
                             value: prop.symbol,
                             group: prop.group,
                             month: prop.month,
                             year:prop.year,

                            }

                 })
              },
              {
                label: "Stocks",
                options: [ {
                              id: "",
                              value: "Coming Soon",
                              group: "",
                              month: "",
                              year:"",
                          }],
                },

      /*  {
          label: "Forex",
          options: financialInstruments.options.Forex.map((prop, key)=>{
                   return{
                     id: key,
                     symbol: prop.symbol,
                     name: prop.name,
                   }
                })
        },
        */
      ],
    })
     GetMyTrades(
                   userRef.user.uid,
                   "time"
                  ).then( (userTrades) => {
                    debugger
                     this.setState(
                           {
                              data: userTrades.trades.map((prop, key) => {
                                                 return {
                                                    id: key,
                                                    timestamp_created: moment(new Date(prop.updateTimeStamp)).format('h:mm a, MMM DD YYYY'),
                                                    symbol: prop.strategy.instrument,
                                                    entryprice: prop.trade.entryPrice,
                                                    trade: prop.trade.type,
                                                    target: prop.trade.profitTarget,
                                                    stoploss: prop.trade.stopLoss,
                                                    tradeprofit:prop.trade.tradeProfit,
                                                    tradevolume: prop.trade.tradeVolume,
                                                  //  strategy:prop.trade.strategy,
                                                    window: prop.trade.validUntil ? moment(new Date(prop.trade.validUntil._seconds * 1000)).format('h:mm a, MMM DD YYYY') : "",
                                                    //(typeof prop.trade.validUntil === 'string' || prop.trade.validUntil instanceof String) ? prop.trade.validUntil : moment(new Date(prop.trade.validUntil._seconds * 1000)).format('h:mm a, MMM DD YYYY') ,
                                                    //prop.trade.validUntil ? moment(new Date(prop.trade.validUntil._seconds * 1000)).format('h:mm a, MMM DD YYYY') : "",
                                                    //new Date(prop.trade.validUntil._seconds * 1000).toLocaleString()
                                                    trade_status: prop.trade_status,
                                                    trades_filled: prop.trade.tradeVolume - prop.trade_status.tobe_filled,
                                                    //trade_status_reason: prop.trade_status.reason,
                                                    trade_id: prop.id,
                                                  };
                                                }),
                             trade: {
                               instrument: "",
                               entryprice: "",
                               trade: "",
                               stoploss: "",
                               tradeprofit: "",
                               target: "",
                               tradevolume: "",
                               validFrom: "",
                               validUntil: "",
                               timestamp_created:"",
                               trade_id:"",
                             },
                             requiredEntryState:"",
                             requiredStopState:"",
                             requiredTargetState:"",
                             requiredVolumeState:"",
                             requiredSymbolState:"",
                             requiredCloseState:"",
                             requiredEntryNumberState:"",
                             requiredStopNumberState:"",
                             requiredTargetNumberState:"",
                             requiredVolumeNumberState:"",
                             requiredValidDateState:"",
                           }
                      )
                }).catch((error) => {
                    console.log("Error trades: ", error);
                });
  }

  // to stop the warning of calling setState of unmounted component
  componentWillUnmount() {
    var id = window.setTimeout(null, 0);
    while (id--) {
      window.clearTimeout(id);
    }
  };

   async clickDeleteTrade(event, tradeID){
     debugger
     event.preventDefault();
     const userRef = this.context

      DeleteTrade(
                    userRef.user.uid,
                    tradeID,
                  ).then( () => {
                    debugger
                    this.successDelete()
                    //window.location.reload(true)
                    console.log("Trade was deleted with following ID: ", tradeID);
      }).catch((error) => {
           console.log("Error trades: ", error);
           this.failDelete();
       });

   }
   async clickMarkToDeleteTrade(event, tradeID){
     debugger
     event.preventDefault();
     const userRef = this.context

      MarkToDeleteTrade(
                    userRef.user.uid,
                    tradeID,
                  ).then( () => {
                    debugger
                    this.successMarkToDelete()
                    //window.location.reload(true)
                    console.log("Trade was marekd to be deleted with following ID: ", tradeID);
      }).catch((error) => {
           console.log("Error trades: ", error);
           this.failMarkToDelete();
       });

   }
   async showControlledResponse(event){
     if (this.state.disabled) {
        return;
    }
    this.setState({disabled: true});
    //  if (isSubmitBlocked) {
    //   console.log("Submit is blocked");
    //   return;
    // }
     isSubmitBlocked = true;
     //to prevent the reload unocomment the line below
     event.preventDefault();

    const userRef = this.context
    if(!this.typeClick())
      return

      CreateTrade(
                      userRef.user.uid,
                      "default",
                      this.state.trade.instrument.value + this.state.trade.instrument.year +  ': ' + this.state.trade.instrument.group,
                      this.state.trade.entryprice,
                      this.state.trade.target,
                      this.state.trade.stoploss,
                      this.state.trade.tradeprofit,
                      this.state.trade.tradevolume,
                      //this.state.trade.strategy,
                      this.state.trade.validUntil._d.toString() )
                  .then( () => {
                        this.setState( prevState => (
                              {
                                trade: {
                                  instrument: "",
                                  entryprice: "",
                                  trade: "",
                                  stoploss: "",
                                  tradeprofit: "",
                                  target: "",
                                  tradevolume: "",
                                  validFrom: "",
                                  validUntil: "",
                                  timestamp_created: "",
                                  trade_id:"",
                                },
                                requiredEntryState:"",
                                requiredStopState:"",
                                requiredTargetState:"",
                                requiredVolumeState:"",
                                requiredSymbolState:"",
                                requiredCloseState:"",
                                requiredEntryNumberState:"",
                                requiredStopNumberState:"",
                                requiredTargetNumberState:"",
                                requiredVolumeNumberState:"",
                                requiredValidDateState: "",
                              })
                         )
                         window.location.reload(true)
                         isSubmitBlocked = false;
                       }
                      ).catch((error) => {
                           console.log("Error trades: ", error);
                            isSubmitBlocked = false;
                       });

  }


  // function that verifies if a string has a given length or not
  verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };

  change(event, stateName, type, stateNameEqualTo, maxValue){

    switch (type) {
      case "length":
        var verifyValue = ""
        if (stateName === "requiredSymbol")
           verifyValue = event.value
        else if (stateName === "requiredClose") //stateName == "requiredOpen" ||
             verifyValue = event.format('YYYY MM DD');
        else if (stateName === "requiredTrade")
           verifyValue = event.toString()
        else
           verifyValue = event.target.value

        if (this.verifyLength(verifyValue, stateNameEqualTo)) {
          this.setState({ [stateName + "State"]: "has-success" });
        } else {
          this.setState({ [stateName + "State"]: "has-danger" });
        }
        break;
      case "max-length":
        if (!this.verifyLength(event.target.value, stateNameEqualTo + 1)) {
          this.setState({ [stateName + "State"]: "has-success" });
        } else {
          this.setState({ [stateName + "State"]: "has-danger" });
        }
        break;
      case "valid-number":
        if(this.validateNumberField(event.target.value)){
            this.setState({ [stateName + "State"]: "has-success" });
        }else {
          this.setState({ [stateName + "State"]: "has-danger" });
        }
      break;
      case "valid-date":
        if(this.isAfterCurrentDateTime(event)){
            this.setState({ [stateName + "State"]: "has-success" });
        }else {
          this.setState({ [stateName + "State"]: "has-danger" });
        }
      break;
      default:
        break;
    }
    this.setState({ [stateName]: verifyValue });
  };

  typeClick = () => {

    var entr = true;
    var stp = true;
    var vol =true;
    var trgt = true;
    var smbl = true;
    var cls = true;
    var enmbr = true;
    var snmbr = true;
    var tnmbr = true;
    var vnmbr = true;
    var vdate = true;

    if (this.state.requiredEntryState === "" || this.state.requiredEntryState === "has-danger" ) {
      this.setState({ requiredEntryState: "has-danger" });
      entr = false;
    }
    if (this.state.requiredStopState === "" || this.state.requiredStopState === "has-danger" ) {
      this.setState({ requiredStopState: "has-danger" });
      stp = false;
    }
    if (this.state.requiredTargetState === "" || this.state.requiredTargetState === "has-danger" ) {
      this.setState({ requiredTargetState: "has-danger" });
      trgt = false;
    }
    if (this.state.requiredVolumeState === "" || this.state.requiredVolumeState === "has-danger" ) {
      this.setState({ requiredVolumeState: "has-danger" });
      vol = false;
    }
    if (this.state.requiredSymbolState === "" || this.state.requiredSymbolState === "has-danger" ) {
      this.setState({ requiredSymbolState: "has-danger" });
      smbl = false;
    }
    if (this.state.requiredCloseState === "" || this.state.requiredCloseState === "has-danger" ) {
      this.setState({ requiredCloseState: "has-danger" });
      cls = false;
    }
    if (this.state.requiredEntryNumberState === "" || this.state.requiredEntryNumberState === "has-danger" ) {
      this.setState({ requiredEntryNumberState: "has-danger" });
      enmbr = false;
    }
    if (this.state.requiredStopNumberState === "" || this.state.requiredStopNumberState === "has-danger" ) {
      this.setState({ requiredStopNumberState: "has-danger" });
      snmbr = false;
    }
    if (this.state.requiredTargetNumberState === "" || this.state.requiredTargetNumberState === "has-danger" ) {
      this.setState({ requiredTargetNumberState: "has-danger" });
      tnmbr = false;
    }
    if (this.state.requiredVolumeNumberState === "" || this.state.requiredVolumeNumberState === "has-danger" ) {
      this.setState({ requiredVolumeNumberState: "has-danger" });
      vnmbr = false;
    }
    if (this.state.requiredValidDateState === "" || this.state.requiredValidDateState === "has-danger" ) {
      this.setState({ requiredValidDateState: "has-danger" });
      vdate = false;
    }

    if(entr && stp && trgt && vol && smbl  && cls && enmbr && snmbr && tnmbr && vnmbr && vdate)//&& trd ) //&& opn //&& strg
      return true
    else
      return false


  };

  getShortLink = () =>{
    const userRef = this.context
    //let trades_provider = new TradesProvider();
    ShareTradeRecord(userRef.user.uid)
                   .then( (shortShareLink) => {
                     copy(shortShareLink.statusMessage)
                     this.setState(
                                              {
                                                 shortLinkValue:shortShareLink.statusMessage,
                                                 copied: true,
                                              }
                                         )
                       }).catch((error) => {
                            console.log("Error trades: ", error);
                            this.setState(
                                  {
                                     shortLinkValue:"",
                                     copied: false,
                                  }
                             )
                        });
  }

isValidDateTime = (current) => {

   var yesterday = moment().subtract( 1, 'day' ).subtract(.5, 'h');
   return current.isAfter( yesterday );
}

isAfterCurrentDateTime = (date) => {

   var now = moment();
   return date.isAfter(now);
}
validateNumberField = myNumber => {
    const numberRegEx =  /^\d+(\.\d{1,5})?$/;
    //    /^[1-9]\d*$/g ;
    debugger
    return numberRegEx.test(String(myNumber).toLowerCase());
}

toggleShowHideTrackRecordTable = ()=> {
  if (this.state.showTrackRecordTable){
    this.setState({showTrackRecordTable:false});
    return false;
  }
    else {
      this.setState({showTrackRecordTable:true})
      return true;
    }
}
hideAlert = () => {
  debugger
 this.setState({
     alert: null,
  });
};

hideSuccessDeleteAlert = () => {
  this.hideAlert();
  window.location.reload(true);
};
successDelete = () => {
  debugger
  this.setState({
      alert: <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Deleted!"
        onConfirm={() => this.hideSuccessDeleteAlert()}
        onCancel={() => this.hideSuccessDeleteAlert()}
        confirmBtnBsStyle="info"
        btnSize=""
      >
        The trade has been deleted.
      </ReactBSAlert>,
   });
 };

 failDelete = () => {
     this.setState({
         alert: <ReactBSAlert
         danger
         style={{ display: "block", marginTop: "-100px" }}
         title="Cancelled"
         onConfirm={() => this.hideAlert()}
         onCancel={() => this.hideAlert()}
         confirmBtnBsStyle="info"
         btnSize=""
       >
         There was an error deleting the trade
       </ReactBSAlert>,
     });
   };
   successMarkToDelete = () => {
     debugger
     this.setState({
         alert: <ReactBSAlert
           success
           style={{ display: "block", marginTop: "-100px" }}
           title="Marked for deletion!"
           onConfirm={() => this.hideSuccessDeleteAlert()}
           onCancel={() => this.hideSuccessDeleteAlert()}
           confirmBtnBsStyle="info"
           btnSize=""
         >
           The trade has been marked for deletion.
         </ReactBSAlert>,
      });
    };

    failMarkToDelete = () => {
        this.setState({
            alert: <ReactBSAlert
            danger
            style={{ display: "block", marginTop: "-100px" }}
            title="Cancelled"
            onConfirm={() => this.hideAlert()}
            onCancel={() => this.hideAlert()}
            confirmBtnBsStyle="info"
            btnSize=""
          >
            There was an error deleting the trade
          </ReactBSAlert>,
        });
      };

 warningConfirmMessage = (rowInfo, e) =>{
  this.setState({alert:
                  <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Are you sure?"
                    onConfirm={() => this.clickMarkToDeleteTrade(e, rowInfo.values.trade_id)}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="info"
                    cancelBtnBsStyle="danger"
                    confirmBtnText="Yes, mark for deletion!"
                    cancelBtnText="Cancel"
                    showCancel
                    btnSize=""
                  >
                  If the trade wasn't already filled at the time of your deletion, it will be removed from your record
                  </ReactBSAlert>
  });

}

 getRoundedDate=(minutes, d=new Date()) =>{

  let ms = 1000 * 60 * minutes; // convert minutes to ms
  let roundedDate = new Date(Math.ceil(d.getTime() / ms) * ms);

  return roundedDate
}

  render() {
    // taking all the states
    let {
      requiredEntryState,
      requiredStopState,
      requiredTargetState,
      requiredVolumeState,
      requiredSymbolState,
      requiredCloseState,
      requiredEntryNumberState,
      requiredStopNumberState,
      requiredTargetNumberState,
      requiredVolumeNumberState,
      requiredValidDateState,
    } = this.state;
    return (
      <>
        <div className="content">
        {this.state.alert}
      {/*
      //     <Row>
      //       <Col>
      //         <Card>
      //           <CardBody>
      //           <Row>
      //             <Col md="3">
      //             <div className="card-user trader">
      //               <a href="" onClick={(e) => e.preventDefault()}>
      //                 <img
      //                   className="trader_profile_avatar"
      //                   src={this.state.trader_photo}
      //                 />
      //                 <h6 className="title">{this.state.trader_firstName + ' ' + this.state.trader_lastName}</h6>
      //               </a>
      //             </div>
      //             </Col>
      //
      //             <Col md="5">
      //               <h6 className="title trader-text-purple">
      //                 TRADER PROFILE
      //               </h6>
      //             <p className="text-muted">
      //              See trades audited with real time market data considering the bid and ask data
      //             </p>
      //
      //               <CopyToClipboard onCopy={this.getShortLink} >
      //                 <Button  color="primary"  type="button">Share My Track Record</Button>
      //               </CopyToClipboard>
      //               <p className="text-muted">
      //                 {this.state.copied ? <span style={{color: '#B603D1'}}> {this.state.shortLinkValue} <br />  Link is generated and copied</span> : null}
      //               </p>
      //             </Col>
      //             </Row>
      //           </CardBody>
      //         </Card>
      //       </Col>
      //     </Row>
            */}

              <Card>
                <CardBody>


                <Row  style={{justifyContent: "space-evenly"}} >
                <Col sm="5">
                      <Card>
                          <h6 className="title">{this.state.trader_firstName + ' ' + this.state.trader_lastName}</h6>
                                <CardBody>

                                <Row>
                                  <Col lg="4" md="5" sm="4" xs="6">
                                  <h5>
                                   <Badge color="primary" pill>
                                     BALANCE
                                   </Badge>
                                   </h5>
                                  </Col>
                                  <Col lg="8" md="7" sm="8" xs="6">
                                    <h5> <b>
                                     $ {this.state.stats.balance}
                                    </b>
                                    </h5>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col lg="4" md="5" sm="4" xs="6">
                                    <p className="trader-text-green">
                                        Initial Investment:
                                    </p>
                                  </Col>
                                  <Col lg="8" md="7" sm="8" xs="6">
                                    <p>
                                     $ {this.state.stats.initial_amount}
                                    </p>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col lg="4" md="5" sm="4" xs="6" >
                                    <p className="trader-text-green">
                                     Markets:
                                    </p>
                                  </Col>
                                  <Col lg="8" md="7" sm="8" xs="6">
                                    <p>
                                      Futures
                                    </p>
                                  </Col>
                                </Row>
                              </CardBody>
                          </Card>
                  </Col>
              <Col sm="5">
                  <Card>
                    <CardBody>
                        <Row>
                          <Col lg="4" md="5" sm="4" xs="6" >
                          <h5>
                           <Badge color="primary" pill>
                             TOTAL TRADES
                           </Badge>
                           </h5>
                          </Col>
                          <Col lg="8" md="7" sm="8" xs="6">
                            <h5> <b>{this.state.stats.total_trades}</b></h5>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="4" md="5" sm="4" xs="6">
                            <p>
                               Verified Positve Trades:
                            </p>
                          </Col>
                          <Col lg="8" md="7" sm="8" xs="6">
                            <h5>
                              <Badge color="success" >{this.state.stats.verified_positive_trades}</Badge>
                            </h5>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="4" md="5" sm="4" xs="6">
                            <p>
                                Verified Negative Trades:
                            </p>
                          </Col>
                          <Col lg="8" md="7" sm="8" xs="6">
                            <h5>
                              <Badge color="danger" pill>{this.state.stats.verified_negative_trades}  </Badge>
                            </h5>
                          </Col>
                        </Row>
                      </CardBody>
                  </Card>
                  </Col>
                 </Row>


                    <Form className="form-horizontal" id="TradeValidation"  onSubmit={(e) =>this.showControlledResponse(e)}>
                      <Card>
                      {/*
                      <CardTitle>
                      <Badge href="#" color="primary">We value your trust and privacy at VerifyTrader</Badge>
                      </CardTitle>
                      */}
                        <CardBody>
                          <Row style={{justifyContent: "space-evenly"}}>
                            <Col sm="3">
                              <label>Search&nbsp;by <b>SYMBOL</b></label>
                              <FormGroup className={requiredSymbolState}>

                                <Select

                                  //className="react-select"
                                //  classNamePrefix="react-select"
                                  name="instrument"
                                  value={this.state.trade.instrument}
                                  onChange={(value) =>(
                                              this.setState(prevState => ({
                                                                trade:{
                                                                        ...prevState.trade,
                                                                           instrument: value }
                                                                      })),
                                              this.change(value, "requiredSymbol", "length", 1)

                                            )
                                  }
                                  options = { this.state.groupedOptions}
                                  formatGroupLabel = {formatGroupLabel}
                                  formatOptionLabel={(option, context) => {
                                      return (
                                        <React.Fragment>
                                        <optgroup label={option.group}>
                                          <option>  {`${option.value} : ${option.month} ${option.year}`} </option>
                                        </optgroup>
                                        </React.Fragment>
                                      );
                                    }}
                                  // getOptionLabel = {option =>
                                  //   <optgroup label={option.group}>
                                  //      <option>  {`${option.text}: ${option.value}`} </option>
                                  //   </optgroup>
                                  //  }
                                   components={{
                                                GroupHeading: HideGroupHeading,
                                                MenuList: HideGroupMenuList
                                              }}

                                   //components={{ GroupHeading: CustomGroupHeading }}
                                    styles={{
                                      groupHeading: (styles) => ({
                                        ...styles,
                                        backgroundColor: "#e9ecef",
                                        margin: 0,
                                        paddingTop: "5px",
                                        paddingBottom: "5px",
                                        color: "black",
                                        fontWeight: 500,
                                        fontSize: "1rem",
                                        flex: 1
                                      })
                                    }}
                                    placeholder="Financial Instrument"
                                 />
                                {this.state.requiredSymbolState === "has-danger" ? (
                                  <label className="error">
                                    This field is required.
                                  </label>
                                ) : null}
                              </FormGroup>
                            </Col>
                           <Col sm="1">
                           <label><b>Entry</b></label>
                              <FormGroup className={requiredEntryState, requiredEntryNumberState}>
                                <Input
                                  className="form-control"
                                  //type={'text'}
                                  type={'controlled'}
                                  //className="form-control"
                                  value={this.state.trade.entryprice}
                                  //onKeyDown={renderProps.onEnterKeyDownConfirm}
                                  onChange={(e) =>{
                                                        var abc = this.state.trade;
                                                        abc.entryprice = e.target.value;
                                                        this.setState({trade: abc});
                                                        this.change(e, "requiredEntry", "length", 1);
                                                        this.change(e, "requiredEntryNumber", "valid-number", 1);
                                                    }
                                            }
                                //  placeholder={'Entry'}
                                />
                                {this.state.requiredEntryState === "has-danger" ? (
                                  <label className="error">
                                    This field is required
                                  </label>
                                ) : null}
                                {this.state.requiredEntryNumberState === "has-danger" ? (
                                  <label className="error">
                                    Enter valid number
                                  </label>
                                ) : null}
                              </FormGroup>
                            </Col>
                            <Col sm="1">
                             <label><b>Target</b></label>
                              <FormGroup className={requiredTargetState,requiredTargetNumberState}>
                              <Input
                                type={'text'}
                                className="form-control"
                                value={this.state.trade.target}
                                //onKeyDown={renderProps.onEnterKeyDownConfirm}
                                //onChange={(e) => this.setState({trader_lastName: e.target.value })}
                                onChange={(e) =>{
                                                      var abc = this.state.trade;
                                                      abc.target = e.target.value;
                                                      this.setState({trade: abc});
                                                      this.change(e, "requiredTarget", "length", 1);
                                                      this.change(e, "requiredTargetNumber", "valid-number", 1);
                                                  }
                                          }
                                //placeholder={'Target'}
                              />
                              {this.state.requiredTargetState === "has-danger" ? (
                                <label className="error">
                                  This field is required.
                                </label>
                              ) : null}
                              {this.state.requiredTargetNumberState === "has-danger" ? (
                                <label className="error">
                                  Enter valid number
                                </label>
                              ) : null}
                              </FormGroup>
                            </Col>
                            <Col sm="1">
                             <label><b>Stop</b></label>
                              <FormGroup className={requiredStopState,requiredStopNumberState}>
                              <Input
                                type={'text'}
                                className="form-control"
                                value={this.state.trade.stoploss}
                                onChange={(e) =>{
                                                      var abc = this.state.trade;
                                                      abc.stoploss = e.target.value;
                                                      this.setState({trade: abc});
                                                      this.change(e, "requiredStop", "length", 1);
                                                      this.change(e, "requiredStopNumber", "valid-number", 1);
                                                  }
                                          }
                              //  placeholder={'Stop'}
                              />
                              {this.state.requiredStopState === "has-danger" ? (
                                <label className="error">
                                  This field is required.
                                </label>
                              ) : null}
                              {this.state.requiredStopNumberState === "has-danger" ? (
                                <label className="error">
                                  Enter valid number
                                </label>
                              ) : null}
                              </FormGroup>
                            </Col>
                            <Col sm="1">
                              <label style={{whiteSpace:"nowrap"}}><b># of Contracts</b></label>
                               <FormGroup className={requiredVolumeState,requiredVolumeNumberState}>
                                 <Input
                                   className="form-control"
                                  //type={'text'}
                                   type={'controlled'}
                                  //className="form-control"
                                  value={this.state.trade.tradevolume}
                                  //onKeyDown={renderProps.onEnterKeyDownConfirm}
                                   onChange={(e) =>{
                                                         var abc = this.state.trade;
                                                         abc.tradevolume = e.target.value;
                                                         this.setState({trade: abc});
                                                         this.change(e, "requiredVolume", "length", 1);
                                                         this.change(e, "requiredVolumeNumber", "valid-number", 1);
                                                     }
                                             }
                                  //placeholder={'Volume'}
                                />
                                 {this.state.requiredVolumeState === "has-danger" ? (
                                   <label className="error">
                                     This field is required.
                                   </label>
                                 ) : null}
                                 {this.state.requiredVolumeNumberState === "has-danger" ? (
                                   <label className="error">
                                     Enter valid number
                                   </label>
                                 ) : null}
                               </FormGroup>
                             </Col>
                           <Col sm="3">
                            <label><b>Valid Until</b></label>
                              <FormGroup className={requiredCloseState, requiredValidDateState}>
                              <ReactDatetime
                                inputProps={{
                                  className: "form-control",
                                  placeholder: "Valid Until",
                                  readOnly: true,
                                }}

                                 defaultValue = {this.getRoundedDate(15)}
                                 isValidDate = {this.isValidDateTime}
                                 closeOnSelect={true}
                                 timeConstraints={{minutes: { min: 0, max: 60, step: 15 }}}
                                 onChange={
                                   ( date ) => (

                                       this.setState( prevState => ({
                                                           trade:{
                                                                   ...prevState.trade,
                                                                       validUntil: date
                                                            }
                                                     })),
                                      this.change(date, "requiredClose", "length", 1),
                                      this.change(date, "requiredValidDate", "valid-date", 1)

                                            )
                                          }
                              />
                              {this.state.requiredCloseState === "has-danger" ? (
                                <label className="error">
                                  This field is required.
                                </label>
                              ) : null}
                              {this.state.requiredValidDateState === "has-danger" ? (
                                <label className="error">
                                  Enter valid date and time.
                                </label>
                              ) : null}
                              </FormGroup>
                            </Col>
                          </Row>
                        </CardBody>
                        <CardFooter className="text-center">
                          <Button
                             color="primary"
                            type="submit"
                            disabled = {!(this.state.requiredStopState === "has-success" &&
                                        this.state.requiredEntryState === "has-success" &&
                                        this.state.requiredCloseState === "has-success" &&
                                        this.state.requiredTargetState === "has-success" &&
                                        this.state.requiredVolumeState === "has-success" &&
                                        this.state.requiredSymbolState === "has-success" &&
                                        this.state.requiredEntryNumberState === "has-success" &&
                                        this.state.requiredStopNumberState === "has-success" &&
                                        this.state.requiredTargetNumberState === "has-success" &&
                                        this.state.requiredVolumeNumberState === "has-success" &&
                                        this.state.requiredValidDateState === "has-success") || this.state.disabled }
                                             >
                            Submit Trade
                          </Button>
                          <Col class="col-md-6 ml-auto mr-auto">
                            <label>We treat your trade record as strictly confidential information.
                            We will not disclose or share your trade record with any third parties without your explicit consent.</label>
                          </Col>
                        </CardFooter>
                      </Card>
                    </Form>

                    <Col md="12">
                    <Card>
                    <Button color="primary"  type="button" onClick={this.toggleShowHideTrackRecordTable}> {this.state.showTrackRecordTable ? "Hide" : "Show" }  Track Record Table</Button>
                    {
                      this.state.showTrackRecordTable ? (

                          <CardBody>
                            <ReactTable
                              id="tradesTable"
                              data={this.state.data}
                              defaultCanSort={true}
                              sorted={this.state.sortOptions}
                              initialState={this.state.initialState}
                              columns={[
                                {
                                  Header: "Date/Time",
                                  accessor: "timestamp_created",
                                  sortType: (a, b) => {
                                                          var a1 = new Date(a).getTime();
                                                          var b1 = new Date(b).getTime();
                                                        if(a1<b1)
                                                        return 1;
                                                        else if(a1>b1)
                                                        return -1;
                                                        else
                                                        return 0;
                                                      },
                                  Cell: ({ cell }) => <div>{cell.row.values.timestamp_created}</div>
                                },
                                {
                                  Header: "Symbol",
                                  accessor: "symbol",
                                  Cell: ({ cell }) => <div>{cell.row.values.symbol}</div>
                                },
                                {
                                  Header: "Entry Price",
                                  accessor: "entryprice",
                                },
                                {
                                  Header: "Target",
                                  accessor: "target",
                                },
                                {
                                  Header: "Stop Loss",
                                  accessor: "stoploss",
                                },
                                {
                                  Header: "Volume",
                                  accessor: "tradevolume",
                                },
                                {
                                  Header: "Profit",
                                  accessor: "tradeprofit",
                                },
                                {
                                  Header: "Trade",
                                  accessor: "trade",
                                },
                                {
                                  Header: "Valid Until",
                                  accessor: "window",
                                   Cell: ({ cell }) => <div>{cell.row.values.window}</div>
                                },
                                {
                                  Header: "Actions",
                                  accessor:  "trade_id",
                                  Cell:({ cell }) => (
                                    <>
                                    <Button
                                        onClick={(event) => {


                                              // here you should add some custom code so you can delete the data
                                              // from this component and from your server as well
                                              debugger
                                              this.warningConfirmMessage(cell.row,event);
                                              // alert(
                                              //   "Are you sure you want to delete this trade? \n{ \id: " +
                                              //     cell.row.values.trade_id +
                                              //     "\n}."
                                              // );
                                              console.log(cell.row.values.trade_id);


                                        }}
                                        disabled = { cell.row.values.trade_status.status == "recorded" ? false: true }
                                        color="danger"
                                        size="sm"
                                        className="btn-icon btn-link remove"
                                      >
                                        <i className="fa fa-times" />
                                      </Button>{" "}
                                    </>
                                  )

                                },
                                {
                                  Header: "Status",
                                  accessor:  "trade_status",
                                  Cell:({ cell }) => (
                                    <>
{/*}
                                    <i id="tooltip264453347" className={ cell.row.values.status == "recorded"
                                          ? "fa fa-question fa-2x"
                                          : ( ( cell.row.values.status == "close" && cell.row.values.tradeprofit > 0)
                                               ? "fa fa-check fa-2x"
                                               : "fa fa-times fa-2x") }

                                                style={ cell.row.values.status == "recorded"
                                                      ? {color:'grey'}
                                                      : ( (cell.row.values.status == "close" && cell.row.values.tradeprofit > 0)
                                                               ? {color:'green'}
                                                               : {color:'red'})
                                                      }

                                      />

                                    <UncontrolledTooltip
                                      delay={0}
                                      target="tooltip264453347"
                                    >
                                        Blockchain Verified
                                    </UncontrolledTooltip>
*/}

<UncontrolledDropdown className="btn-rotate" >
  <DropdownToggle
    aria-haspopup={true}
    caret
    color={cell.row.values.trade_status.status == "recorded"
            ? "default"
            : ( cell.row.values.trade_status.status == "close"
                ?  "success"
                : (cell.row.values.trade_status.status == "partial"
                    ? "warning"
                    : "danger" ) )
              }
    data-toggle="dropdown"
    >
      {cell.row.values.trade_status.status}
    </DropdownToggle>
    <DropdownMenu
      persist
      right>
      <DropdownItem> <b>{"trades filled: "} </b>  {cell.row.values.trade_status.tobe_filled == -1
                                                    ? 0
                                                    : (cell.row.values.tradevolume - cell.row.values.trade_status.tobe_filled) } </DropdownItem>
      <DropdownItem>  {cell.row.values.trade_status.reason == "stop_loss"
                      ? "trade hit stop loss"
                      :(cell.row.values.trade_status.reason == "valid_until"
                         ? "trade expired"
                         : (cell.row.values.trade_status.reason == "profit_target"
                            ? "trade hit target profit"
                            : ""))} </DropdownItem>
    </DropdownMenu>
</UncontrolledDropdown>
                                    </>
                                  )

                                },

                              ]}
                              defaultSorted={[
                                {
                                  id: "timestamp_created",
                                  desc: true
                                }
                              ]}
                              /*
                                You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                              */
                              className="-striped -highlight primary-pagination"

                            />
                          </CardBody>

                    ):null
                    }
                    </Card>
                  </Col>
                </CardBody>
              </Card>

        </div>
      </>
    );
  }
}
export default MyProfile;
